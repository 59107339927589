.signup-container {
  background-color: #2c2c2c;
}
.login-text {
  text-align: center;
  color: #fff;
}
#already-have-account {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}
#already-have-account a {
  font-size: 1rem;
  font-weight: 500;
}
#already-have-account span {
  padding: 0;
}
#already-have-account .form-check-input {
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
}
#already-have-account .form-check-input:checked {
  background-color: #f44336;
  border-color: #f44336;
}
#already-have-account .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(253 99 13 / 25%);
}
.forget-title {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}
