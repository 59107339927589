@charset "utf-8";
/* CSS Document */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.calBox{
    margin-top: 60px;
    background: #484848;
    box-shadow: 0px 4px 7px 3px rgb(0 0 0 / 5%);
    border-radius: 20px;
    margin-bottom: 50px;
}
.calBoxHead{
    border-bottom: 1px solid #2C2C2C;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding: 20px 15px;
    text-align: center;
    position: relative;
}
.calBoxHead span{ padding: 0 10px;}
.vcRgt{
    position: absolute;
    right: 20px;
    top: 20px;
    color: #fff;
}
.calBtm{ padding: 15px;color: white;}
.rbc-toolbar{color: white!important; }
.calInfo{
    width: 100%;
    border-radius: 20px;
    background-color: #595959;
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
}
.redTx{ color: #ff0000;}
.maxW{ max-width: 350px;}
.calTable{ width: 100%; overflow: auto;}

.calTable table{
    margin: 0;
    padding: 20px;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    min-width: 900px;
  }
  .calTable tr th{ text-align: center; color: #fff; font-weight: 500; padding: 30px 15px;}
  .calTable tr{
    /* border: 1px solid #E0607E; */
  }
  .calTable td{
    border-left: 1px solid #2C2C2C;
    border-top: 1px solid #2C2C2C;
    padding: 10px;
    color: #FFF;
    font-size: 15px;
    width: 14%;
    vertical-align: top;
    height: 180px;
  }
  .calTable td h5{
      color: #FF9900;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      padding-bottom: 6px;
  }
  .calTable td p{
    color: #8C8C8C;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
}
  .calTable td:last-child{ border-right: 1px solid #2C2C2C;}
  .calTable tr:nth-child(2) td:first-child{
    border-top-left-radius: 15px;
  }  
  .calTable tr:first-child td:first-child{
    border-top-left-radius: 15px;
  }  
  .calTable tr:last-child td:first-child{
    border-bottom-left-radius: 15px;
  }
 
  .calTable tr:first-child td:last-child{
    border-top-right-radius: 15px;
  }
  .calTable tr:nth-child(2) td:last-child{
    border-top-right-radius: 15px;
  }
 
  .calTable tr:last-child td:last-child{
    border-bottom-right-radius: 15px;
  }
  .calTable tr:last-child td{ border-bottom: 1px solid #2C2C2C;}


  .prcBar{ padding-top: 10px; padding-bottom: 15px;}
  .prcBtn{
    background: #595959;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 7px;
    font-size: 11px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    padding: 3px 10px;
  }
  .prcIcn{ font-size: 22px;}
  .prcRedCircle{ 
      background-color: #EC3525;
      border-radius: 100px;
      padding: 6px;
      width: 40px;
      height: 40px;
      text-align: center;
      display: inline-block;
    }
