.actvUpld{
    background-color: #EC3525!important;
}
.playerPhotopage{
    padding: 30px;
    display: block!important;
    height: auto;
}
.ppHead{
    border-bottom: 1px solid #2c2c2c;
    padding-bottom: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.ppHead select{
    background: #595959 url(../../../images/select-dropdown.png) no-repeat 90% center;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding: 5px 35px 5px 20px;
    border-radius: 100px;
    height: 50px;
    border:none;
    appearance: none;
    outline: none;
    box-shadow: none;
}
.ppTablebg{ 
    width: 100%;
    overflow: auto;
}
.ppTablebg table tr th, .ppTablebg table tr td{
    border: none!important;
}
.ppTablebg table tr th{
    border-bottom: 1px solid #2c2c2c!important;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.ppTablebg table tr td{
    border-bottom: 1px solid #2c2c2c!important;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    vertical-align: middle;
}
.ppTablebg table tr:last-child td{ border-bottom: 0!important;}
.ppImg img{
    border-radius: 100px;
    border: 2px solid #fff;
    width: 50px;
    height: 50px;
}