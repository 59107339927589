@charset "utf-8";
/* CSS Document */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
a {
  color: #747948;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  color: #747948;
}
a img {
  border: 0;
}
h1,
h1 a {
  color: #260f02;

  font-size: 28px;
  font-weight: normal;
  margin: 0;
}
h2,
h2 a {
  color: #260f02;

  font-size: 26px;
  font-weight: normal;
  margin: 0;
}
h3,
h3 a {
  color: #260f02;

  font-size: 22px;
  font-weight: normal;
  margin: 0;
}
h4,
h4 a {
  color: #767b49;

  font-size: 20px;
  font-weight: normal;
  margin: 0;
}
h5,
h5 a {
  color: #212121;

  font-size: 18px;
  font-weight: normal;
  margin: 0;
}
h6,
h6 a {
  color: #707445;

  font-size: 16px;
  font-weight: normal;
  margin: 0;
}
p,
ul,
ol {
  color: #212121;

  font-size: 14px;
  font-weight: normal;
  margin: 0;
  line-height: 21px;
}
.dashboard-container {
  margin: 0;
  padding: 0;
  position: relative;
}
.dashboard-side-bar {
  width: 25%;
  background: #2c2c2c;
  padding: 0px 0px 20px;
}
.dashboard-main {
  display: flex;
}
.nav-header {
  display: none;
}

.dashboard-main-content {
  padding: 30px 24px 0;
  width: 100%;
  background: #2c2c2c;
}
.dashboard-head {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}
.teams-select {
  display: flex;
  align-items: center;
  width: auto;
}
.teams-select select {
  width: 260px;
  background: #484848;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  height: 60px;
  border: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  outline: 0;
  background: #484848 url(../../../images/select-dropdown.png) no-repeat 93%
    center;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 0 25px;
  margin-right: 10px;
}
.teams-select button.create-new-team {
  width: 210px;
  background: #484848;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  height: 60px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  border: none;
  outline: 0;
  margin-right: 10px;
}
.create-teams {
  margin: 0;
  padding: 0;
  margin-left: 20px;
}
.create-teams button {
  width: 215px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-size: 16px;
  color: #ec3525;
  border: none;
  outline: 0;
  padding: 0;
}
.create-teams button img {
  max-width: 100%;
  width: 28px;
}
.login-account {
  margin-left: auto;
}
.login-account ul {
  margin: 0;
  padding: 0;
}
.login-account ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
  border-left: 1px solid #fff;
  line-height: 15px;
}
.login-account ul li:first-child {
  border: none;
}
.login-account ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 0 10px;
}
.profile-head {
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
}
.profile-head-img img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  background: #f45648;
}
.profile-head-name {
  font-size: 16px;
  color: #fff;
  width: 100%;
  font-weight: 500;
  padding-right: 12px;
}
.three-dots {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
}
.three-dots span {
  display: block;
  width: 4px;
  height: 4px;
  background: #c9c9c9;
  border-radius: 50%;
  margin: 0 2px;
}
.dashboard-top-content {
  display: flex;
  padding-top: 8px;
}
.dashboard-top-content-left {
  width: 52%;
}
.team-profile {
  width: 40%;
  background: #595959;
  /*    box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);*/
  border-radius: 20px;
  padding: 44px 20px 30px;
}
.team-profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  background: #f45648;
}
.team-profile-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 5px solid #fff;
  border-radius: 50%;
}
.logo {
  padding: 0 15px;
}
.logo img {
  max-width: 100%;
  width: 210px;
}
.team-profile-name {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  margin-top: 15px;
}
.update-team-photo {
  background: #ec3525;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  height: 50px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin-top: 40px;
  position: relative;
}
.update-team-photo input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
}
.dashboard-top-content-left-top {
  display: flex;
  justify-content: space-between;
}

.invoice-due {
  width: 55%;
  margin-left: 5%;
  background: #484848;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.invoice-due h2 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.invoice-due p {
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.invoice-due span {
  font-size: 14px;
  line-height: 18px;
  color: #959595;
  margin: 0;
  padding: 0;
}
.invoice-icon {
  margin: 25px 0;
}
.dublin-weather {
  background: #484848;
  border-radius: 15px;
  padding: 35px 25px;
}

.dublin-weather h2 {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  margin: 0 0 25px 0;
  padding: 0;
}

.dublin-weather-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dublin-weather-main-inner {
  display: flex;
  align-items: center;
}
.dublin-weather-main-inner-text {
  padding-left: 30px;
}
.dublin-weather-main-inner-text h4 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin: 0 0 5px 0;
  padding: 0;
}
.dublin-weather-main-inner-text h5 {
  font-weight: 500;
  font-size: 25px;
  color: #ff9d04;
  margin: 0 0 5px 0;
  padding: 0;
}
.dublin-weather-main-inner-text span {
  font-weight: normal;
  font-size: 14px;
  color: #adadad;
  margin: 0;
  padding: 0;
  display: block;
}
.dublin-weather-main-inner-img img {
  width: 82px;
}
.dublin-weather-bottom {
  display: flex;
  justify-content: space-between;
}
.dublin-weather-bottom-boxes {
  width: 23%;
  background: #595959;
  border-radius: 15px;
  text-align: center;
  padding: 15px 10px 10px;
  min-height: 154px;
}
.dublin-weather-bottom-boxes h3 {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}
.dublin-weather-bottom-boxes img {
  width: 55px;
  margin: 4px 0 12px;
}
.dublin-weather-bottom-boxes h6 {
  color: #646464;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.dublin-weather-bottom-boxes h6 span {
  color: #adadad;
}

.active-degree span {
  display: block;
  font-weight: 500;
  font-size: 28px;
  color: #ff9d04;
  line-height: 28px;
}
.active-degree p {
  font-size: 14px;
  color: #adadad;
  margin: 0;
  padding: 0;
}

.dashboard-top-content-right {
  width: 45.5%;
  margin-left: 2.5%;
  background: #484848;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.team-list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}
.team-list-head h2 {
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  margin: 0;
  padding: 0;
}
.team-list-head a {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 30px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
}
.team-list-section {
  padding: 0px 15px 22px;
}
.team-list-box {
  position: relative;
}
.team-list-box-img {
  width: 90px;
}
.team-list-box-img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 5px solid #fff;
  overflow: hidden;
  object-fit: cover;
}
.team-list-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: #595959;
  border-radius: 15px;
  padding: 13px 15px;
}
.team-list-box:last-child {
  margin-bottom: 0;
}
.team-list-box-text {
  width: 100%;
  padding-left: 15px;
  position: relative;
}
.team-list-box-text h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.team-list-box-text h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: #9e9e9e;
  margin: 8px 0 0 0;
  padding: 0;
}
.team-list-box-text h5 span {
  margin-left: 15px;
}
.team-list-box-text a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ec3525;
  margin: 7px 0 0 0;
  padding: 0;
  display: block;
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.player-schedule-section {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.dashboard-top-content-left-bottom {
  margin-top: 30px;
  border-radius: 15px;
  overflow: hidden;
}

.dashboard-schedule-section {
  background: #484848;

  border-radius: 20px;
  width: 45.5%;
}

.dashboard-schedule-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}
.dashboard-schedule-head h2 {
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  margin: 0;
  padding: 0;
}
.dashboard-schedule-head a {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 25px;
  height: 45px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
}

.dashboard-schedule-main-box {
  padding: 0 25px;
}
.dashboard-schedule-main-box-option {
  display: flex;
}
/*custom radio*/
.options-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 50px;
}

/* Hide the browser's default checkbox */
.options-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 26px;
  width: 26px;
  border: 2px solid #ec3525;
  border-radius: 50%;
  background: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.options-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.options-radio .checkmark:after {
  left: 3px;
  top: 3px;
  width: 16px;
  height: 16px;
  background: #ec3525;
  border-radius: 50%;
}
/*custom radio*/

.dashboard-schedule-game-event-text h2 {
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  margin: 0 0 20px 0;
  padding: 0;
}
.dashboard-schedule-game-event-text ul {
  margin: 0;
  padding: 0;
}
.dashboard-schedule-game-event-text ul li {
  list-style-type: none;
  margin: 0 0 18px 0;
  padding: 0;
}
.dashboard-schedule-game-event-text ul li a {
  font-size: 18px;
  color: #434343;
  text-decoration: none;
  background: url(../../../images/right-arrow.png) no-repeat right center;
  padding-right: 30px;
}
.reminder-btn {
  width: 330px;
  height: 55px;
  background: #f8f8fa;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-weight: 500;
  font-size: 16px;
  color: #ec3525;
  border: none;
  padding: 0;
  margin-top: auto;
  outline: 0;
}
.dashboard-schedule-game-event {
  display: flex;
  margin-top: 15px;
}
.dashboard-schedule-game-event-calender {
  width: 100%;
  padding-bottom: 30px;
}
.dashboard-schedule-game-event-calender img {
  width: 100%;
}

.record-standing_section {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.record-standing-box {
  background: #484848;
  border-radius: 20px;
  width: 52%;
  padding: 30px 25px 30px;
}
.record-standing-head {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}
.record-save-box-inner {
  background: #595959;
  padding: 30px 20px;
  border-radius: 20px;
  margin-top: 20px;
}
.record-main-top {
  margin: 0;
  padding: 0;
}
.record-main-top h4 {
  font-weight: 500;
  font-size: 25px;
  color: #ffffff;
  margin: 35px 0 0 0;
  padding: 0;
}

.record-main-top span {
  font-weight: normal;
  font-size: 20px;
  color: #c4c4c4;
}
.record-main-top button {
  border: none;
  width: 106px;
  height: 32px;
  font-weight: normal;
  font-size: 14px;
  background: #ff9900;
  color: #ffffff;
  border-radius: 18px;
}
.record-line-bar {
  margin-top: 42px;
}
.record-line-bar img {
  width: 100%;
}
.enter-result {
  margin-top: 98px;
}
.enter-result-btn {
  background: #ec3525;
  border-radius: 25px;
  border: none;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  width: 151px;
  height: 50px;
  outline: 0;
}
.standing-table {
  border-radius: 15px;
  overflow: hidden;
  background: #595959;
  margin-top: 20px;
  padding-bottom: 15px;
}

.standing-table table {
  width: 100%;

  overflow: hidden;
}
.standing-table table tr th {
  padding: 25px 15px;
  border-bottom: 1px solid #484848;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
}
.standing-table table tr th,
.standing-table table tr td {
  text-align: center;
}
.standing-table table tr th:first-child,
.standing-table table tr td:first-child {
  text-align: left;
}
.standing-table table tr td {
  padding: 12px 15px;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}

.record-save-section {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.record-save-box {
  background: #484848;
  border-radius: 20px;
  width: 52%;
  padding: 30px 25px 30px;
}
.save-travel {
  background: #484848;
  border-radius: 20px;
  width: 45.5%;
  width: 45.5%;
  padding: 30px 25px 30px;
}
.save-on-travel-img {
  margin-bottom: 15px;
}
.save-on-travel h4 {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.save-on-travel p {
  font-weight: normal;
  font-size: 16px;
  color: #969696;
  margin: 14px 0 0 0;
  padding: 0;
  line-height: 25px;
}
.save-on-travel a {
  width: 214px;
  height: 50px;
  line-height: 50px;
  background: #ec3525;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  font-size: 16px;
  font-weight: normal;
  color: #ffff;
  display: block;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
}
.myteam-teamsnap-section {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.myteam-list-section {
  margin-top: 20px;
}
.myteam-teamsnap-section .team-list-box-img img {
  border-width: 2px;
}
.myteam-teamsnap-section .my-team-details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.myteam-teamsnap-section .my-team-details .name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ec3525;
}
.myteam-teamsnap-section .my-team-details .category,
.myteam-teamsnap-section .my-team-details .season {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #b0b0b0;
}

.teamsnap-section {
  background: #484848;
  border-radius: 20px;
  width: 45.5%;
}
.teamsnap-section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}
.teamsnap-section-head h2 {
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  margin: 0;
  padding: 0;
}
.teamsnap-section-head a {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 25px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
}
.teamsnap-section-main {
  padding: 0px 25px 30px;
}
.teamsnap-list-box {
  margin-bottom: 15px;
  background: #595959;
  border-radius: 15px;
  padding: 18px 15px;
}
.teamsnap-list-box:last-child {
  margin-bottom: 0;
}
.teamsnap-list-box p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.teamsnap-list-box span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #b0b0b0;
  margin: 0;
  padding: 0;
}
.footer {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.footer .copy-right {
  font-size: 15px;
  color: #7b7b7b;
}
.footer .copy-right a {
  color: #7b7b7b;
  text-decoration: none;
}
.social-icon-footer p {
  font-size: 15px;
  color: #555555;
  margin: 0;
  padding: 0;
}
.social-icon-footer p a {
  margin: 0 5px;
}

.custom-modal .modal-content {
  background: #424242;
  border-radius: 35px;
}
.custom-modal .modal-content .form-control {
  background: #424242;
  color: #fff;
}
.custom-modal .modal-content label {
  color: #fff;
}
.custom-modal .close {
  position: absolute;
  right: 40px;
  top: 32px;
  opacity: 1;
  padding: 0;
  outline: 0;
}
.custom-modal .close img {
  width: 25px;
  height: 25px;
}
.custom-modal .modal-header {
  justify-content: center;
  border: none;
}
.custom-modal .modal-title {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
}
.custom-modal .modal-body {
  padding: 1rem 2rem 2rem 2rem;
}
.btn-set {
  text-align: center;
  margin-top: 2rem;
}
.custom-modal .btn-deflt {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 30px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
}

/*team-shop-page*/

.team-shop-page {
  margin-top: 10px;
}

.my-order-section {
  margin: 0;
  padding: 0;
  text-align: right;
}
.my-order-section a {
  width: 151px;
  height: 50px;
  background: #ec3525;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  border-radius: 25px;
  display: block;
  text-decoration: none;
  text-align: center;
  margin-left: auto;
}
.team-shop-list-box {
  padding: 30px 25px;
  background: #484848;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-top: 35px;
}
.sort-by-section {
  display: flex;
}
.sort-by-section-main {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.sort-by-section-main select {
  width: 176px;
  background: #595959;
  border-radius: 25px;
  height: 50px;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  background: #595959 url(../../../images/select-dropdown.png) no-repeat 92%
    center;
  font-weight: normal;
  font-size: 16px;
  color: #d3d3d3;
  padding: 0 24px;
}
.sort-by-section-main label {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: #ffffff;
  margin: 0 12px 0 0;
  padding: 0;
}
.team-shop-list-main {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.team-shop-product-box {
  width: 32.33%;
  background: #595959;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 1.5%;
}
.team-shop-product-img img {
  max-width: 100%;
  margin: 0 auto;
}
.team-shop-product-text {
  margin-top: 35px;
}
.team-shop-product-text h2.product-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.team-shop-product-text p.product-description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #d5d5d5;
  margin: 15px 0 0 0;
  padding: 0;
}
.team-shop-product-text .product-price {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ec3525;
}
.team-shop-product-text .product-price {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ec3525;
  margin-top: 14px;
}
.team-shop-product-text .product-size {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #d5d5d5;
  margin-top: 9px;
}
/*team-shop-page*/

/*prefarance-page*/
.prefarance-page {
  min-height: 900px;
}
.page-title {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
  margin: 10px 0 0 0;
  padding: 0;
}
.prefarance-box {
  margin-top: 60px;
  background: #484848;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  min-height: 550px;
}
.prefarance-box ul.nav-tabs {
  width: 28%;
  border-right: 1px solid #2c2c2c;
  padding: 30px 30px;
  flex-direction: column;
  border-bottom: none;
}
.prefarance-box .nav-tabs .nav-link {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.prefarance-box .nav-tabs .nav-item {
  margin-bottom: 20px;
}

.prefarance-box .nav-tabs .nav-item.show .nav-link,
.prefarance-box .nav-tabs .nav-link.active {
  background: none;
  color: #ff9900;
  border: none;
}
.prefarance-box .nav-tabs .nav-link:focus,
.prefarance-box .nav-tabs .nav-link:hover {
  border: none;
  color: #ff9900;
}
.prefarance-box .tab-content {
  width: 72%;
  padding: 36px 30px;
}
.prefarance-tab-content h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.prefarance-form-list {
  margin: 20px 0 0 0;
  padding: 0;
}
.prefarance-form-list label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 15px 0;
  padding: 0;
}
.prefarance-form-list-box {
  display: flex;
  align-items: center;
}
.prefarance-form-list-box .input-select {
  width: 55%;

  border: 1px solid #2c2c2c;
  border-radius: 10px;
  height: 60px;
  outline: 0;
  padding: 0 25px;
  font-size: 15px;
  color: #a7a7a7;
  background: #484848 url(../../../images/select-dropdown.png) no-repeat 95%
    center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.prefarance-form-list-box p {
  width: 45%;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  padding-left: 20px;
}
.social-share p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 156.4%;
  color: #a7a7a7;
  margin: 20px 0 0 0;
  padding: 0;
}
.social-share p a {
  text-decoration: none;
  color: #ff9900;
  font-weight: 500;
}
.social-share a.connect-toif {
  width: 206px;
  height: 50px;
  background: #ec3525;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  display: block;
  line-height: 50px;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  margin-top: 40px;
}
/*prefarance-page*/

/*liability-waiver*/
.liability-box {
  width: 900px;
  margin: 30px auto 50px auto;
}
.player-details-section {
  display: flex;
  justify-content: space-between;
}
.player-details-box span {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  display: block;
}

.player-details-box p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}
.pdf-image {
  margin-top: 50px;
}
.pdf-image img {
  width: 100%;
}
.liability-form {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.liability-form-input {
  width: 40%;
  margin-bottom: 25px;
}
.liability-form-input label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: block;
  color: #ffffff;
  margin: 0 0 15px 0;
  padding: 0;
}
.liability-form-input .liability-input-field {
  width: 100%;
  height: 58px;
  background: #484848;
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  outline: 0;
  padding: 0 15px;
}

.team-payment {
  margin: 60px 0 0 0;
  padding: 0;
  width: 100%;
}
/*liability-waiver*/

/*team-payment*/
.team-payment table {
  width: 100%;
}
.team-payment table tr {
  border-bottom: 1px solid #2c2c2c;
}
.team-payment table tr:first-child,
.team-payment table tr:last-child {
  border: none;
}
.team-payment table tr td,
.team-payment table tr th {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 22px 25px;
}
.profile-name-img img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  margin-right: 10px;
}
.team-payment table tr td a.view-invoice {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
}

.team-payment table tr td a.pay-now {
  font-weight: 500;
  font-size: 16px;
  color: #ff9900;
  text-decoration: none;
}
.team-payment table tr td span.paid {
  color: #979797;
}
.invoice-show .modal-body {
  padding: 0;
}
.invoice-show .modal-body .invoice-img {
  padding-top: 65px;
}
.invoice-show .modal-body .invoice-img img {
  width: 100%;
}
.invoice-download {
  width: 130px;
  height: 45px;
  background: #ec3525;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  color: #fff;
  outline: 0;
}
/*team-payment*/

/*player-assesment*/
.flag-prac .practice {
  width: 115px;
  height: 35px;
  border: 1px solid #fff;
  border-radius: 7px;
  background: #595959;
  font-size: 15px;
  color: #fff;
}
.flag-prac {
  display: flex;
  margin-bottom: 15px;
}
.flag-prac img {
  margin-right: 15px;
}
.game-name {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  color: #ff9900;
}
.team-assesment table tr td,
.team-assesment table tr th {
  padding: 25px 18px;
  font-size: 16px;
  vertical-align: top;
}
.team-assesment table tr td span {
  color: #9e9e9e;
}
.team-assesment .add-btn {
  text-align: center;
  margin-top: 10px;
}
.team-assesment .add-btn button {
  padding: 0;
  border: none;
  background: none;
}
.last-row {
  display: flex;
  align-items: center;
}
.last-row p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.last-row button {
  border: none;
  background: none;
  padding: 0;
  margin-left: 10px;
}
.team-assesment table tr td:first-child,
.team-assesment table tr th:first-child {
  width: 21%;
}
.team-assesment table tr td:nth-child(4n),
.team-assesment table tr th:nth-child(4n) {
  width: 20%;
}
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header .add-new-btn {
  width: 136px;
  height: 50px;
  background: #ec3525;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 50px;
  display: block;
  border-radius: 25px;
  margin-right: 15px;
}
.page-header .view-preferance {
  width: 178px;
  height: 50px;
  background: #484848;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 50px;
  display: block;
  border-radius: 25px;
}
.right-head-buttons {
  display: flex;
  align-items: center;
}

.assesment-modal .modal-content {
  padding: 0 50px;
}
.assesment-modal .modal-content .form-control {
  background: #484848;
  border: 1px solid #2c2c2c;
  border-radius: 6px;
  height: 50px;
}
.assesment-modal .modal-content label {
  text-align: center;
  display: block;
}
.cancel-save-btn-set {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-save-btn-set .btn-deflt {
  width: 130px;
  margin: 0 10px;
}
.cancel-save-btn-set .btn-deflt.cancel {
  background: #8a8a8a;
}
.save-create-btn {
  margin-top: 25px;
  text-align: center;
}

#assignmentpreferance .modal-content {
  padding-top: 30px;
  padding-bottom: 30px;
}
#assignmentpreferance .modal-body {
  text-align: center;
}

/* Customize the label (the container) */
#assignmentpreferance .hide-game-check {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.hide-game-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.hide-game-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background: #353535;
  border-radius: 4px;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.hide-game-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.hide-game-check .checkmark:after {
  left: 9px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.delect-assment-modal {
  text-align: center;
}
.delect-assment-modal p {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.delect-assment-modal h5 {
  font-size: 16px;
  color: #ffffff;
  margin: 25px 0 20px 0;
  padding: 0;
  font-weight: 600;
}
/*player-assesment*/

/*Player team Roaster*/

.team-roaser-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.team-roaser-head .team-roaser-head-right select {
  width: 178px;
  height: 50px;
  background: #484848;
  border-radius: 25px;
  color: #ffffff;
  font-size: 16px;
  padding: 0 20px;
  border: none;
  outline: 0;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: #484848 url(../../../images/select-dropdown.png) no-repeat 93%
    center;
  background-size: 14px;
}
.team-roaser-head-right button.team-roaser-head-btn {
  width: 232px;
  height: 50px;
  border-radius: 25px;
  background: #ec3525;
  border: none;
  font-size: 16px;
  color: #fff;
  padding: 0;
  margin-left: 15px;
}
.team-roaster-table {
  display: block;
}
.team-roaster-table-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 30px;
  border-bottom: 1px solid #2c2c2c;
}
.team-roaster-table-head p {
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.team-roaster-table-head span {
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.team-roaster-table .team-payment {
  margin-top: 0;
}
.team-roaster-table .profile-name-icon {
  display: flex;
  align-items: center;
}
.team-roaster-table .profile-name-icon img.table-profile {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  margin-right: 10px;
}
.team-roaster-table .mic-account {
  margin-left: 10px;
}
.team-roaster-table .mic-account h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 0 0 8px 0;
  background: url(../../../images/tick-circle.png) no-repeat right center;
  padding-right: 25px;
}
.team-roaster-table .contact-info h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.team-roaster-table .contact-info span {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #9e9e9e;
  margin: 0;
  padding: 0;
}
.team-roaster-table .account-icon {
  margin-left: 10px;
}
.team-roaster-table-bottom {
  margin-top: 40px;
  min-height: 350px;
}

/*player info*/
.player-info-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.player-info-head .player-info-head-right button.edit-btn {
  width: 106px;
  height: 50px;
  border-radius: 25px;
  background: #484848;
  font-size: 16px;
  color: #fff;
  border: none;
  outline: 0;
}

.player-info-head .player-info-head-right button.add-new-family {
  width: 267px;
  height: 50px;
  border-radius: 25px;
  background: #ec3525;
  font-size: 16px;
  color: #fff;
  border: none;
  outline: 0;
  margin-left: 12px;
}
.player-info-head.availability .teams-select select {
  height: 50px;
  margin-left: auto;
}
.prefarance-box.player-info ul.nav-tabs {
  padding: 30px 20px;
}

.prefarance-form-list label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 15px 0;
  padding: 0;
  display: block;
}
.prefarance-form-list-box {
  display: flex;
  align-items: center;
}
.playerinfo-form .input-select {
  width: 100%;
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  height: 60px;
  outline: 0;
  padding: 0 25px;
  font-size: 15px;
  color: #a7a7a7;
  background: #424242;
}

.player-profile-img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
}

button.add-links {
  width: 158px;
  height: 50px;
  background: #ec3525;
  font-size: 16px;
  border-radius: 25px;
  color: #fff;
  border: none;
  outline: 0;
}
button.add-links-secondary {
  width: 158px;
  height: 50px;
  background: #1d1b1b;
  font-size: 16px;
  border-radius: 25px;
  color: #fff;
  border: none;
  outline: 0;
}

.add-links-secondary:hover {
 
  background:#fff;
 
  color: #1d1b1b;

}
/*player info*/

/*edit family*/
.information-box {
  background: #595959;
  border: 1px solid #2c2c2c;
  padding: 20px 30px;
  text-align: center;
  border-radius: 15px;
}
.information-box h6 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.information-box p {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  margin: 15px 0 0 0;
  padding: 0;
}

.non-player {
  padding-bottom: 30px;
  border-bottom: 1px solid #2c2c2c;
}

/*custom radio*/
.prefarance-form-list label.options-check-family {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  padding-top: 3px;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.options-check-family input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  opacity: 0;
  left: 0;
  top: 0;
}

/* Create a custom checkbox */
.options-check-family .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background: #353535;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.options-check-family .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.options-check-family input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.options-check-family .checkmark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*custom radio*/

.person1-details {
  margin-top: 25px;
  padding-bottom: 40px;
  border-bottom: 1px solid #2c2c2c;
}
.person1-details h4 {
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: #fff;
}
.family-check {
  display: flex;
  align-items: center;
}
.family-check .prefarance-form-list {
  margin-right: 20px;
}
.family-check .prefarance-form-list:last-child {
  margin-right: 0;
}
.delect-addemail {
  text-align: right;
  margin-top: 15px;
}

.delect-addemail button {
  border: none;
  background: none;
  padding: 0;
  outline: 0;
}
.delect-addemail button img {
  width: 18px;
}
.delect-addemail button.add-email {
  color: #ec3525;
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.playerinfo-form .input-select.calender-white {
  background: url(../../../images/white-calender.png) 95% center no-repeat;
}
.person2-details {
  border-bottom: none;
}

.button-set-family {
  margin: 0;
  padding: 0;
  text-align: right;
}
.button-set-family button {
  background: #8a8a8a;
  width: 151px;
  height: 50px;
  border-radius: 25px;
  border: none;
  font-size: 15px;
  color: #fff;
  margin-left: 20px;
  outline: 0;
}
.button-set-family button.save {
  background: #ec3525;
}
/*edit family*/

/*streaming*/
.streming-head-right {
  display: flex;
  align-items: center;
}
.streming-head-right ul {
  margin: 0;
  padding: 0;
  background: #484848;
  border-radius: 25px;
  margin-right: 20px;
  overflow: hidden;
}
.streming-head-right ul li {
  list-style-type: none;
  margin: 0;
  display: inline-block;
  padding: 0;
}
.streming-head-right ul li a {
  font-size: 16px;
  color: #fff;
  padding: 13px 22px;
  display: block;
  text-decoration: none;
  line-height: 24px;
}
.streming-head-right ul li a.active {
  background: #ec3525;
  color: #fff;
}

.start-stream-btn {
  width: 187px;
  height: 50px;
  border-radius: 25px;
  background: #484848;
  border: none;
  font-size: 16px;
  color: #fff;
  outline: 0;
}
.upload-stream-btn {
  width: 187px;
  height: 50px;
  border-radius: 25px;
  background: #ec3525;
  border: none;
  font-size: 16px;
  color: #fff;
  outline: 0;
  margin-right: 20px;
}
.streaming-section {
  padding: 30px 30px 70px;
  display: block;
}
.strame-chat-section {
  display: flex;
  justify-content: space-between;
}
.strame-main {
  width: 65%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333333;
  text-align: center;
}
.strame-main h3 {
  font-weight: 600;
  font-size: 22px;
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 35px;
}

.strame-main p {
  font-weight: normal;
  font-size: 16px;
  color: #fff;
  margin: 0;
  line-height: 28px;
  padding: 0;
}
.chat-main {
  width: 33%;
  background: #333333;
  padding: 20px 30px;
  position: relative;
}
.chat-main-input {
  position: absolute;
  left: 4%;
  bottom: 15px;
  width: 92%;
}
.chat-main-input .input-field {
  background: #484848;
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  background: #484848;
  height: 60px;
  width: 100%;
  font-size: 16px;
  color: #fff;
  outline: 0;
  padding: 0 40px 0 15px;
}
.chat-main-input .input-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

.chat-main-input .input-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  opacity: 1;
}

.chat-main-input .input-field::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
  opacity: 1;
}
.chat-main h4 {
  color: #ec3525;
  font-weight: 600;
  font-size: 22px;
}
.chat-main .chat-send-btn {
  border: none;
  background: none;
  padding: 0;
  outline: 0;
  position: absolute;
  right: 12px;
  top: 19px;
}
.chat-main .chat-send-btn img {
  width: 20px;
}

.streaming-form-section {
  margin-top: 30px;
}
.streaming-form-field-set {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.streaming-form-field-set .streaming-form-field-left {
  width: 65%;
}
.streaming-form-field-set .streaming-form-field-right {
  width: 33%;
}

.playerinfo-form .input-textarea {
  width: 100%;

  border: 1px solid #2c2c2c;
  border-radius: 10px;
  height: 184px;
  outline: 0;
  padding: 20px 25px;
  font-size: 15px;
  color: #a7a7a7;
  background: none;
  resize: none;
}

.select-allmedia {
  margin-top: 30px;
}
.stream-table-box {
  margin-top: 30px;
  display: block;
}
.stream-table-box-head {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.stream-table-box-head {
  padding: 25px 20px;
  border-bottom: 1px solid #2c2c2c;
}
.stream-table-box-head .teams-select {
  width: 178px;
}
.stream-table-box-head .teams-select select {
  height: 50px;
  background: #595959 url(../../../images/select-dropdown.png) no-repeat 93%
    center;
  box-shadow: none;
  font-weight: normal;
  font-size: 15px;
  background-size: 15px;
}
.stream-table-box .team-payment {
  margin: 0;
}
/*streaming*/
.invoice-due {
  overflow: hidden;
}
.invoice-due .create-new-team-section {
  width: 100%;
}
.invoice-due .create-new-team-banner img {
  width: 100%;
}
.invoice-due .create-new-team-text {
  padding: 15px;
}
.invoice-due .create-new-team-text h3 {
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.invoice-due .create-new-team-text p {
  font-size: 14px;
  text-align: center;
  color: #959595;
  margin: 9px 0 0 0;
  padding: 0;
}
.invoice-due .create-new-team-text button {
  background: #ec3525;
  box-shadow: 0px 4px 7px 3px rgb(0 0 0 / 5%);
  border-radius: 25px;
  height: 50px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin-top: 15px;
  position: relative;
  border: none;
  width: 180px;
  outline: 0;
}
.pie-chat-total-income img {
  width: 100%;
}
.tb-section {
  background: none;
  padding: 0;
}
.standing-tb-section {
  background: #484848;
  padding: 30px 25px 30px;
  border-radius: 20px;
  overflow: hidden;
}
.tb-section .standing-table {
  background: none;
}
.tb-section .standing-table table tr,
.tb-section .standing-table table th {
  border-bottom: 1px solid #2c2c2c;
}
.tb-section .standing-table table tr:last-child {
  border-bottom: none;
}
.tb-section .standing-table table tr th,
.tb-section .standing-table table tr td {
  padding-left: 0;
  padding-right: 0;
}

.dropBtn button {
  box-shadow: 0px 4px 3px rgb(0 0 0 / 10%);
  border-radius: 30px;
  background: #484848 url(../../../images/select-dropdown.png) no-repeat 88%
    center !important;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 0 50px 0 25px;
  height: 60px;
  border: none;
  box-shadow: none !important;
  min-width: 200px;
}
.dropBtn button::after {
  display: none;
}
.dropBtn button:hover,
.dropBtn button:active,
.dropBtn button:focus {
  border: none;
  outline: none;
  background: #484848 url(../../../images/select-dropdown.png) no-repeat 88%
    center !important;
  box-shadow: none;
}
.dropBtn .dropdown-menu {
  background-color: #2c2c2c !important;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
}
.dropBtn li {
  border-top: 1px solid #363636;
}
.dropBtn li {
  padding: 0;
}
.dropBtn li {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 20px;
}
.dropBtn li:hover {
  color: #ec3525;
  background: transparent;
  text-decoration: none;
}
.dropBtn li.active {
  background-color: #ec3525;
  color: #fff;
}

.playerOther {
  display: block !important;
}
.playerOther p {
  padding: 25px 30px;
  font-size: 16px;
  color: #fff;
}
.playerLists {
  width: 100%;
  display: flex;
  padding: 30px;
}
.playerLstLft {
  width: 50%;
}
.playerLstRgt {
  width: 50%;
}
.playerLstLft h4,
.playerLstRgt h4 {
  font-size: 18px;
  color: #fff;
  padding-bottom: 25px;
}
.invite_player_btn {
  margin-top: 1rem;
  background-color: rgb(236, 53, 37);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
}
.invite_player_btn:hover {
  color: #fff;
  background-color: rgb(243, 54, 37);
}
.playerRow {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 16px;
  margin-bottom: 20px;
}
.playerRow img {
  width: 80px;
  margin-right: 20px;
  border-radius: 100px;
}
.playerColor {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}
.pcA {
  background-color: #ccc;
}
.pcB {
  background-color: #ff0000;
}
.playerLstRgt .playerRow {
  justify-content: flex-start;
}
.playerLstRgt input[type="checkbox"] {
  margin-right: 50px;
  width: 15px;
  height: 15px;
}

.ordrHdn {
  display: flex;
  justify-content: space-between;
}

.tsListshop {
  background-color: #595959;
  border-radius: 20px;
  padding: 25px;
  margin-bottom: 45px;
}
.tsLpart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.tsLpBlog h6 {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  padding-bottom: 10px;
}
.tsLprice {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}
.sizeQty {
  font-size: 13px;
  font-weight: 300;
  color: #fff;
  padding-bottom: 5px;
}
.tsLpBlog p {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}
.dividr {
  width: 100%;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 1px solid #353535;
  margin-bottom: 15px;
}
.prevOrdr {
  text-align: right;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.photosTp {
  display: flex;
  justify-content: space-between;
}
.photoProgress {
  display: flex;
  align-items: center;
}
.photoProgress .progress {
  width: 300px;
  height: 10px;
  background-color: #000;
  border-radius: 100px;
  margin: 0 20px;
}
.photoProgress .progress .progress-bar {
  background-color: #ec3525;
}
.photoTpBtm {
  margin-top: 20px;
  padding-top: 30px;
}
.photoTpBtm p {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  color: #fff;
  padding-bottom: 15px;
}
.addFirstvideo {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 30px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
  display: inline-block;
  margin-top: 15px;
}
.addFirstvideo:hover {
  background: #333;
  color: #fff;
  text-decoration: none;
}
.redTxt {
  color: #ec3525;
}
.filesBtms {
  font-size: 17px;
  color: #fff;
}
.filesDnld {
  padding: 0;
  color: #ec3525;
  margin-right: 30px;
}
.filepdnLft {
  display: inline-block;
  margin-left: 20px;
}
.fileUploadSec {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.fileUploadSec button {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 30px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
  display: inline-block;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 15px;
}

.teamOrgbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}
.teamOrgbar a {
  width: 151px;
  height: 50px;
  background: #ec3525;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  border-radius: 25px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
}
.teamBtnRgt a {
  margin-right: 10px;
  background: #484848;
}
.teamOrgbar a:hover {
  background: #ec3525;
  text-decoration: none;
  color: #fff;
}

.teamOrgselect {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}
.slctLft {
  display: flex;
}
.slctPart {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-right: 15px;
}
.slctPart span {
  padding: 0 0 5px 0;
}
.teamOrgselect select {
  background: #2c2c2c;
  border: 1px solid #484848;
  border-radius: 5px;
  padding: 10px 18px;
  color: #fff;
  font-size: 13px;
}
.teamOrgBox {
  padding: 30px;
  display: block !important;
}
.teamOrgForbmg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.teamorgSlct {
  width: 32%;
  display: flex;
  flex-direction: column;
}
.teamOrgBox label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 0 0 15px 0;
  padding: 0;
  display: block;
}
.teamOrgBox select {
  width: 100%;
  background-color: #484848 !important;
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  height: 50px;
  outline: 0;
  padding: 0 25px;
  font-size: 15px;
  color: #a7a7a7;
  background: none;
}
.charSect {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.chartLft {
  width: 68%;
}
.chartRgt {
  width: 30%;
  background-color: #595959;
  border-radius: 20px;
  padding: 30px;
}
.chartRgt table {
  width: 100%;
}
.chartRgt table th,
.chartRgt table td {
  color: #fff;
  border: none;
}
.chartRgt table th:nth-child(2),
.chartRgt table td:nth-child(2) {
  text-align: center;
}
.chartRgt table td {
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #333;
}

.formDateBg {
  flex-direction: row;
  justify-content: space-between;
}
.formDate {
  width: 47%;
}
.formDate .form-control {
  width: 100%;
  background: #484848 !important;
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  height: 50px;
  outline: 0;
  padding: 0 15px;
  font-size: 15px;
  color: #a7a7a7;
  background: none;
}
.toFlex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.toflexInn {
  width: 47%;
}
.teamOrtsmallHdn {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 35px 0;
  padding: 0;
  display: block;
}
.dividrB {
  width: 100%;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 1px solid #353535;
  margin-bottom: 30px;
  margin-top: 15px;
}
.fullWidth {
  width: 100%;
}
.toFoot {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.toFoot span {
  color: #ec3525;
  font-size: 13px;
  cursor: default;
}

.teamOrgbar input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.slctPart label {
  padding: 0 0 5px 0;
  margin: 0;
  color: #fff;
}
.slctPart .form-control {
  background: #2c2c2c;
  border: 1px solid #484848;
  border-radius: 5px;
  padding: 10px 18px;
  color: #fff;
  font-size: 13px;
  height: 42px;
}
.brkNewteam {
  width: 151px;
  height: 50px;
  background: #ec3525;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  border-radius: 25px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  margin-right: 10px;
  margin-top: 23px;
}
.brkNewteam:hover {
  color: #fff;
  text-decoration: none;
}

.teamSheduleBg {
  padding: 30px;
  display: block !important;
}
.sheduledHd {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
}
.teamSheduleBg table {
  width: 100%;
  border: none;
}
.teamSheduleBg table th {
  border-bottom: 1px solid #333 !important;
  border-top: 0;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}
.teamSheduleBg table td {
  border-top: 0;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  border-bottom: 0;
  padding-top: 20px;
}
.teamSheduleBg table td p {
  color: #ccc;
  font-size: 12px;
}
.sheduleTable {
  width: 100%;
  overflow: auto;
}
.bracketsBox {
  padding: 30px;
  height: auto;
}
.brkLft {
  margin-right: 20px;
}
.bracketsBox p {
  color: #fff;
  font-size: 16px;
}
.brkLbordr {
  border: 1px solid #9c9c9c;
  margin-top: 20px;
  width: 120px;
  display: inline-block;
}
.brkLft span {
  border-bottom: 1px solid #9c9c9c;
  float: left;
  width: 100%;
  height: 40px;
}

.dialBgCover {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.dialBgWrap {
  position: relative;
  margin-right: 25px;
  padding-right: 25px;
}
.dialBgWrap::before {
  content: "";
  position: absolute;
  right: 0;
  top: 25%;
  width: 25px;
  height: 49.5%;
  border-right: 1px solid #9c9c9c;
  border-top: 1px solid #9c9c9c;
  border-bottom: 1px solid #9c9c9c;
}
.dialBgWrap::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 49.5%;
  width: 25px;
  height: 0;
  border-top: 1px solid #9c9c9c;
}

.dia1 {
  display: flex;
  align-items: center;
}
.dialBgCover p {
  font-size: 12px;
  font-weight: 400;
}
.dialBgCover span {
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  height: 40px;
  border: 1px solid #9c9c9c;
  display: inline-block;
  margin-bottom: 3px;
  padding: 5px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.diaSubchild {
  position: relative;
  margin-bottom: 25px;
}
.diaSub1,
.diaSub2,
.diaSub3 {
  width: 160px;
  margin-right: 25px;
  padding-right: 25px;
  position: relative;
}
.diaSub3 {
  padding-right: 0;
  margin-right: 0;
}
.diaSub1::before {
  content: "";
  position: absolute;
  right: 0;
  top: 62px;
  width: 25px;
  height: 50.5%;
  border-right: 1px solid #9c9c9c;
  border-top: 1px solid #9c9c9c;
  border-bottom: 1px solid #9c9c9c;
}
.diaSub1::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 49.5%;
  width: 25px;
  height: 0;
  border-top: 1px solid #9c9c9c;
}
.diaSub2::before {
  content: "";
  position: absolute;
  right: 0;
  top: 40px;
  width: 25px;
  height: 35.5%;
  border-right: 1px solid #9c9c9c;
  border-top: 1px solid #9c9c9c;
  border-bottom: 1px solid #9c9c9c;
}
.diaSub2::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 49.5%;
  width: 25px;
  height: 0;
  border-top: 1px solid #9c9c9c;
}
.diaBgMain {
  display: flex;
  align-items: center;
}
.diabg {
  margin-right: 25px;
  padding-right: 25px;
  position: relative;
}
.diabg::before {
  content: "";
  position: absolute;
  right: 0;
  top: 123px;
  width: 25px;
  height: 50.5%;
  border-right: 1px solid #9c9c9c;
  border-top: 1px solid #9c9c9c;
  border-bottom: 1px solid #9c9c9c;
}
.diabg::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 49.5%;
  width: 25px;
  height: 0;
  border-top: 1px solid #9c9c9c;
}
.tournamentDetl {
  margin-top: 30px;
  background: #484848;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 30px;
}
.tournamentDetl p {
  font-size: 16px;
  color: #fff;
}
.tourDetlInn {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.tdRgt {
  width: 30%;
  padding-left: 20px;
  border-left: 1px solid #353535;
}
.tdRgt ul li {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding-bottom: 10px;
}
.tdLft {
  width: 35%;
}
.tdLft table {
  width: 100%;
}
.tdLft table th,
.tdLft table td {
  color: #fff;
}
.tdLft table td {
  font-weight: 400;
  font-size: 14px;
  padding: 10px 0;
}
.tdLft table td span {
  background-color: #595959;
  display: inline-block;
  padding: 10px;
  width: 100%;
}
.tdLft table td:nth-child(2) {
  width: 100px;
}
.tdLft table tfoot {
  border-top: 1px solid #353535;
}

.teamRegdBg {
  padding: 30px;
}
.teamRegdtable {
  width: 100%;
}
.teamRegdtable table {
  width: 100%;
}
.teamRegdtable table th,
.teamRegdtable table td {
  border: none !important;
  color: #fff;
}
.teamRegdtable table th {
  border-bottom: 1px solid #333 !important;
}
.teamRegdtable table td {
  font-size: 13px;
  font-weight: 400;
  padding-top: 25px;
}
.greenCol {
  color: #007c0c;
}
.redCol {
  color: #ec3525;
}
.trBtn {
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #595959;
  display: inline-block;
  padding: 3px 10px;
}

/* -------------newTeam-start------------- */
.teamOrgBox input[type="text"] {
  width: 100%;
  background-color: #484848 !important;
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  height: 50px;
  outline: 0;
  padding: 0 25px;
  font-size: 15px;
  color: #a7a7a7;
  background: none;
}
.netTmsingle {
  float: right;
  width: 158px;
  margin-bottom: 25px;
  margin-top: 20px;
}
.newTMbox {
  height: auto;
}
.netWm_check {
  display: flex;
  align-items: center;
}
.netWm_check .form-check {
  display: inline-block;
}
.netWm_check span {
  font-size: 13px;
  font-weight: 400;
  padding-right: 20px;
}
.netWm_check .form-check-input:checked {
  background-color: #ec3525;
  border-color: #ec3525;
  background-image: none;
}
.newTm_submit {
  text-align: right;
}
.newTm_submit a {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 25px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
  display: inline-block;
}
.newTm_submit a:hover {
  color: #fff;
  text-decoration: none;
}

/* -------------newTeam-end------------- */

/* --------TeamStatistic-start-------- */
.statisticHead {
  width: 100%;
  border: 1px solid #484848;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.statisticHead .active {
  color: #ec3525;
}
.statisticHead h5 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-right: 50px;
}
.statisticHead span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-right: 60px;
  display: inline-block;
}
.teamStatisticBase {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  height: auto;
  align-items: flex-start;
}
.tStLft {
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  width: 38%;
}
.tStRgt {
  width: 60%;
}
.tStLftHead {
  border-bottom: 1px solid #2c2c2c;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 10px 15px;
  margin: 0;
}
.tStLftBtm {
  width: 100%;
  display: flex;
}
.tStLftBtm span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 15px 0;
}
.tStLftBtmLft {
  width: 60%;
  padding: 10px 15px;
  border-right: 1px solid #2c2c2c;
}
.tStLftBtmRgt {
  width: 40%;
  padding: 10px 15px;
}
.tstRgt_inn {
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
}
.tStLft_cont {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
}
.tStLft_cont span {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  border-right: 1px solid #2c2c2c;
  padding: 10px 15px;
  display: inline-block;
}
.tStLft_cont > span {
  min-width: 16%;
}
.tStLft_cont > span:last-child {
  border-right: 0 !important;
}
.tsGrey {
  color: #9e9e9e !important;
  border: none !important;
  padding: 0 5px !important;
}
.teamStat_table th:nth-child(1),
.teamStat_table td:nth-child(1) {
  width: 200px;
}

.teamStat_table th:nth-child(2),
.teamStat_table td:nth-child(2) {
  width: 30px;
}
.teamStat_table th:last-child,
.teamStat_table td:last-child {
  width: 80px;
}
.tmEdt {
  font-size: 18px;
  cursor: default;
}
/* --------TeamStatistic-end-------- */

/* --------PlayerStats-start-------- */
.playerStatsBg {
  padding: 30px;
  display: block !important;
  height: auto;
}
.playStat_Head {
  width: 100%;
  border-bottom: 1px solid #2c2c2c;
  padding: 10px 15px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.playStat_hright {
  display: flex;
  align-items: center;
}
.playStat_hright .teams-select select {
  background-color: #2c2c2c;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  width: auto;
  margin-left: 20px;
  padding-right: 40px;
}
.playStatTablebg {
  width: 100%;
}
.playStatTablebg table {
  width: 100%;
}
.playStatTablebg table th,
.playStatTablebg table td {
  border: none;
}
.playStayUsr {
  display: flex;
  align-items: center;
}
.playStayUsr img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 10px;
  border: 2px solid #fff;
}
.playStatTablebg table td {
  vertical-align: middle;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.playStatTablebg table th {
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #333 !important;
}
.playStatTablebg table th:nth-child(1),
.playStatTablebg table th:nth-child(2) {
  text-align: left;
}
.playStatTablebg table td:nth-child(1),
.playStatTablebg table td:nth-child(2) {
  text-align: left;
}
.playLft_text {
  text-align: left !important;
}
.playLft_textB {
  text-align: left !important;
  text-indent: 15px;
}
.playStatTablebg table tfoot th {
  border-top: 1px solid #333 !important;
  border-bottom: 0 !important;
}
.gameStBtn {
  background-color: #595959;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  display: inline-block;
  padding: 2px 5px;
  font-size: 12px;
  margin-left: 5px;
}

/* --------PlayerStats-end-------- */

/* --------ManageStats-start-------- */
.msBtnBg {
  justify-content: space-between;
}
.msBtns a {
  background: #484848;
  border-radius: 100px;
  display: inline-block;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  height: 50px;
  padding: 0 20px;
  line-height: 49px;
  border: 1px solid #484848;
}
.msBtns a:hover {
  text-decoration: none;
  background: transparent;
  color: #fff;
}
.msBtnBg .teams-select select {
  box-shadow: none;
  background-color: #2c2c2c;
  padding: 0;
  width: auto;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  padding: 10px 25px 10px 25px;
  height: auto;
}
.msGp {
  color: #9e9e9e !important;
}
.manageStats_table table th,
.manageStats_table table td {
  text-align: left;
}
.manageStats_table table td {
  border: 0 !important;
}
.manageStats_table table tfoot {
  border-top: 1px solid #2c2c2c;
}
.msUsr {
  text-align: right !important;
}
.manageStats_table .form-check-input {
  background-color: #353535;
  border-color: #353535;
}
.manageStats_table .form-check-input:focus {
  box-shadow: none !important;
}
.manageStats_table .form-check-input:checked {
  background-color: #007c0c;
  border-color: #007c0c;
}

/* --------ManageStats-end-------- */

/* --------NewStatistic-start-------- */
.newStatisticBg {
  padding: 30px;
  height: auto;
}
.newStatisticBg form {
  width: 100%;
}
.newStatisBlog {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.newStb_Lft {
  width: 46%;
}
.newStb_Rgt {
  width: 46%;
  display: flex;
  justify-content: space-between;
}
.newStatisBlog label {
  font-size: 15px;
  color: #fff;
}
.newStatisBlog .form-control {
  background-color: #484848;
  border: 1px solid #2c2c2c;
  border-radius: 7px;
  font-size: 13px;
  color: #fff;
  height: 40px;
  margin-bottom: 22px;
}
.newStatisBlog .form-control::placeholder {
  color: #9e9e9e;
}
.newStatisBlog .form-control:focus {
  box-shadow: none !important;
}

.newSt_child_a {
  width: 53%;
}
.newSt_child_b {
  width: 43%;
}
.newStb_Rgt h6 {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 13px;
}
.newStb_Rgt p {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 6px;
}
.newStb_Rgt p.shade {
  color: #9e9e9e;
}
.newStbSlct {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 20px;
}
.newStbSlct select {
  background-color: #484848;
  border: 1px solid #2c2c2c;
  border-radius: 7px;
  font-size: 13px;
  color: #fff;
  height: 40px;
}
.newStatisBlog .form-check {
  padding-bottom: 20px;
}
.newStatisBlog .form-check-label {
  font-size: 13px;
  font-weight: 400;
}
.newStatisBlog .form-check-input {
  background-color: #353535;
  border-color: #353535;
}
.newStatisBlog .form-check-input:focus {
  box-shadow: none !important;
}
.newStatisBlog .form-check-input:checked {
  background-color: #007c0c;
  border-color: #007c0c;
}
.newSt_submit button {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 30px;
  height: 45px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  border: none;
  outline: none;
  margin-right: 10px;
}
.newSt_submit button.cancl {
  background-color: #8a8a8a;
}
.newSt_submit button.cancl:hover {
  background-color: #ec3525;
}
.newSt_submit button.saveanother {
  background-color: #2c2c2c;
}
.newSt_submit button.saveanother:hover {
  background-color: #ec3525;
}
.newSt_submit button.saves:hover {
  background-color: #333;
}

/* --------NewStatistic-end-------- */

/* --------RecordStatistic-start-------- */
.recordStatisticBg {
  display: block !important;
}
.doneBtn {
  background: #2c2c2c;
  border-radius: 25px;
  padding: 0 30px;
  height: 45px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  display: inline-block;
}
.doneBtn:hover {
  text-decoration: none;
  color: #fff;
  background: #ec3525;
}
.recordForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  flex-wrap: wrap;
}
.recordForm span {
  border: 1px solid #2c2c2c;
  border-radius: 6px;
  display: inline-block;
  padding: 12px 20px;
  text-align: center;
  font-size: 13px;
  color: #9e9e9e;
  width: 24%;
  margin-bottom: 15px;
}
.recordstBtm {
  text-align: right;
  padding: 15px;
}

/* --------RecordStatistic-end-------- */
.acceptbtn {
  background: #ec3525;
  color: #fff;
  border-radius: 30px;
  margin-right: 0.5rem;
  padding: 0.35rem 1.5rem;
}
.acceptbtn:hover {
  color: #fff;
  background: #ff2613;
}
.prefarance-box.mail_body .tab-content {
  width: 75%;
  padding: 20px; box-sizing: border-box;
}
.mail_body {
  height: calc(100% - 105px);
}
.mail-body {
  display: flex;
  background: #424141;
  padding: 8px;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 10px;
  /* cursor: pointer; */
  width: 100%;
  
}
 .mail-item {
  /* background: #424141; */
  align-items: center;
  width: 90%;
  cursor: pointer;
  display:contents
}
 .mail-item .mail-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
}
 .mail-item .item-name {
  margin: 0 16px;
  font-size: 16px;
  color: #fff;
}

.mail-item .item-subj {
  max-width: 60%;
  color: #cfc6c6;
  font-size: 15px;
}
 .action-icon {
  margin-left: auto;
  width: 16px;
  height: 16px;
}
.action-icon:hover {
  margin-left: auto;
  width: 20px;
  height: 20px;
  color: #fff;
  cursor: pointer

}
.text-eclipse-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
}

.viewMail-body .mail_sender-data {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;
}
.viewMail-body .mail_sender-data .senderImage {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
  border: 2px solid #666464;
  background: #383737;
}
.viewMail-body .mail_sender-data .sndr-content {
  flex: 1;
  margin-left: 10px;
}
.viewMail-body .sndr-content .sndr-name {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.viewMail-body .sndr-content .sndr-email {
  color: #ddd;
  font-weight: 300;
}
.to-email {
  color: #ddd;
  font-weight: 300;
  font-size: 12px;
  padding-right: 10px;
  
}
.viewMail-body .mail-body {
  margin-bottom: 16px;
  font-size: 15px;
  color: #e9e9e9;
  font-weight: 300;
}
.viewMail-body .btn-act .btn {
  border-radius: 30px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}
.viewMail-body .btn-act .btn-outline-danger {
  color: #ec3525;
  border-color: #ec3525;
}
.viewMail-body .btn-act .btn-outline-danger:hover {
  color: #fff;
  background-color: #ec3525;
}


/* 15-9-2023 */
.alert-modal .btn-act {
  position: absolute;
  top: -70px;
  right: 10px;
}

.alert-modal .btn-act .btn {
  border: none;
  font-size: 44px;
  line-height: 28px;
  padding: 5px 5px;
  color: #fff;
}
.alert-modal-w .modal-dialog  { max-width: 600px;}
.alert-modal-w .m-title { color: #fff; font-size: 1.6em;}

.alert-modal-w .modal-header {border-bottom: 1px solid #7f7f7f !important;margin-bottom: 20px;}
.alert-modal-w  .viewMail-body .sndr-content .sndr-name { font-size: 1.3em; margin-bottom: 0px}
.alert-modal-w  .viewMail-body .sndr-content .sndr-email { font-size: 1em; margin-bottom: 5px}

.alert-modal-w  .viewMail-body  .to-email {font-size: 1em;border: 1px solid;border-radius: 999px;padding: 3px 10px;}

.alert-modal-w .to-email-w { display: flex; gap: 8px; padding-top: 10px;}

.alert-modal-w .viewMail-body .mail-body {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  border-radius: 0;
  font-size: 1em;
  margin-bottom: 0 !important;
}


.custom-team-schedule-tbl table  tr th:first-child, .custom-team-schedule-tbl table  tr td:first-child { width: 100px !important;}
.custom-team-schedule-tbl .flag-prac .practice {
  max-width: 150px;
  height: auto;
}

.prefarance-form-list ul {
  width: 100%;
  padding: 0 25px;
}

.prefarance-form-list ul li {
  align-items: center;
  margin-bottom: 25px;
}

.prefarance-form-list ul li .graphic {
  min-width: 100px;
  text-align: center;
}

input.custom-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid #7e7e7e;
}

input.custom-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  background-color: #ec3525; border-color: #ec3525;
}

/* player style */
.mail-item-row {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 100px;
  position: relative;
}

.mail-item-row .info-and-graphic {
  display: flex;
  align-items: center;
}

.mail-item-row .mail-item .item-name {
  min-width: 200px;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
}
.mail-item-row  .actions {
  display: flex;
  position: absolute;
  top: 5px;
  right: 10px;
}

.btn-small-primary {
  background-color: #ec3525 !important;
  color: #fff; padding: 5px 15px; border-radius: 999px;
} 
.btn-small-primary span, .btn-small-secondary span {color: #fff !important;}

.btn-small-secondary {
  background-color: rgb(29, 27, 27) !important;
  color: #fff; padding: 5px 15px; border-radius: 999px;
} 

.custom-button-groups { display: flex; flex-wrap: wrap; gap: 15px; justify-content: flex-end;}

.custom-checkbox-w .form-check-input {
  background: transparent;
  border: 2px solid #909090;
  height: 24px;
  width: 24px;
  margin-left: -35px;
  margin-top: 2px;
}

.form-check.custom-checkbox-w {
  padding-left: 35px;
}

.mail_body .accorDianbg { padding-left: 15px;}