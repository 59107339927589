.login-container {
  background-color: #2c2c2c;
  height: 100vh;
}
.login-text {
  text-align: center;
  color: #fff;
}
#account {
  text-align: center;
  margin-top: 15px;
  color: #bebebe;
  font-size: 1rem;
}
.forget-title {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}
.login_box {
  background: #595959;
  max-width: 600px;
  margin: auto;
  border-radius: 1rem;
  padding: 1.5rem;
  margin-top: 2rem;
}
.login_box .login_box_header {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
.login_box .subtxt_login {
  font-size: 1rem;
  text-align: center;
  color: #fff;
  margin-top: 1rem;
  font-weight: 200;
}
.login_box label {
  font-weight: 400;
  font-size: 1rem;
  color: #fff;
}
.login_box .btn-deflt {
  background: #ec3525;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 30px;
  min-width: 9rem;
}
.login_box .btn-deflt:hover {
  color: #fff;
  background: #ff2e1b;
}
.login_box .form-control,
.login_box .form-control:focus {
  background: #595959;
  color: #fff;
}
.login_box .form-group span {
  padding: 0;
}
