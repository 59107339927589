@charset "utf-8";
/* CSS Document */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

a {
  color: #747948;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #747948;
}

a img {
  border: 0;
}

h1,
h1 a {
  color: #260f02;

  font-size: 28px;
  font-weight: normal;
  margin: 0;
}

h2,
h2 a {
  color: #260f02;

  font-size: 26px;
  font-weight: normal;
  margin: 0;
}

h3,
h3 a {
  color: #260f02;

  font-size: 22px;
  font-weight: normal;
  margin: 0;
}

h4,
h4 a {
  color: #767b49;

  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

h5,
h5 a {
  color: #212121;

  font-size: 18px;
  font-weight: normal;
  margin: 0;
}

h6,
h6 a {
  color: #707445;

  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

p,
ul,
ol {
  color: #212121;

  font-size: 14px;
  font-weight: normal;
  margin: 0;
  line-height: 21px;
}

.dashboard-container {
  margin: 0;
  padding: 0;
  position: relative;
}

.dashboard-side-bar {
  width: 25%;
  background: #2c2c2c;
  padding: 0px 0px 20px;
}

.dashboard-main {
  display: flex;
}

.nav-header {
  display: none;
}

.menulist {
  margin: 45px 0 0 0;
  padding: 0;
}

.menulist ul {
  margin: 0;
  padding: 0;
}

.menulist ul li {
  list-style-type: none;
  margin: 0 0 10px 0;
  padding: 0;
}

.menulist ul li {
  font-size: 15px;
  text-decoration: none;
  color: #e1e1e1;
  display: block;
  position: relative;
  padding: 22px 15px 22px 70px;
  background: red;
  border-radius: 0px 35px 35px 0;
  transition: 0.5s;
}

.menulist ul li.menu1 {
  background: url(../../images/menu1.png) no-repeat 25px center;
  background-size: 32px;
}

.menulist ul li.menu2 {
  background: url(../../images/menu2.png) no-repeat 25px center;
  background-size: 28px;
}

.menulist ul li.menu3 {
  background: url(../../images/menu3.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li.menu4 {
  background: url(../../images/menu4.png) no-repeat 25px center;
  background-size: 28px;
}

.menulist ul li.menu5 {
  background: url(../../images/menu5.png) no-repeat 25px center;
  background-size: 28px;
}

.menulist ul li.menu6 {
  background: url(../../images/menu6.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li.menu7 {
  background: url(../../images/menu7.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li.menu8 {
  background: url(../../images/menu8.png) no-repeat 25px center;
  background-size: 28px;
}

.menulist ul li.menu9 {
  background: url(../../images/menu9.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li.menu10 {
  background: url(../../images/menu10.png) no-repeat 25px center;
  background-size: 31px;
}

.menulist ul li.menu11 {
  background: url(../../images/menu11.png) no-repeat 25px center;
  background-size: 31px;
}

.menulist ul li.menu12 {
  background: url(../../images/menu12.png) no-repeat 25px center;
  background-size: 34px;
}

.menulist ul li.menu13 {
  background: url(../../images/menu13.png) no-repeat 25px center;
  background-size: 23px;
}

.menulist ul li.menu14 {
  background: url(../../images/menu14.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li.menu15 {
  background: url(../../images/menu15.png) no-repeat 25px center;
  background-size: 27px;
}

.menulist ul li.menu16 {
  background: url(../../images/menu16.png) no-repeat 25px center;
  background-size: 22px;
}

/*hover*/
.menulist ul li a.menu1:hover,
.menulist ul li.menu1.active {
  background: #ec3525 url(../../images/menu1.png) no-repeat 25px center;
  background-size: 32px;
}

.menulist ul li a.menu2:hover,
.menulist ul li.menu2.active {
  background: #ec3525 url(../../images/menu2.png) no-repeat 25px center;
  background-size: 28px;
}

.menulist ul li a.menu3:hover,
.menulist ul li.menu3.active {
  background: #ec3525 url(../../images/menu3.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li a.menu4:hover,
.menulist ul li.menu4.active {
  background: #ec3525 url(../../images/menu4.png) no-repeat 25px center;
  background-size: 28px;
}

.menulist ul li a.menu5:hover,
.menulist ul li.menu5.active {
  background: #ec3525 url(../../images/menu5.png) no-repeat 25px center;
  background-size: 28px;
}

.menulist ul li a.menu6:hover,
.menulist ul li.menu6.active {
  background: #ec3525 url(../../images/menu6.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li a.menu7:hover,
.menulist ul li.menu7.active {
  background: #ec3525 url(../../images/menu7.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li a.menu8:hover,
.menulist ul li.menu8.active {
  background: #ec3525 url(../../images/menu8.png) no-repeat 25px center;
  background-size: 28px;
}

.menulist ul li a.menu9:hover,
.menulist ul li.menu9.active {
  background: #ec3525 url(../../images/menu9.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li a.menu10:hover,
.menulist ul li.menu10.active {
  background: #ec3525 url(../../images/menu10.png) no-repeat 25px center;
  background-size: 31px;
}

.menulist ul li a.menu11:hover,
.menulist ul li.menu11.active {
  background: #ec3525 url(../../images/menu11.png) no-repeat 25px center;
  background-size: 31px;
}

.menulist ul li a.menu12:hover,
.menulist ul li.menu12.active {
  background: #ec3525 url(../../images/menu12.png) no-repeat 25px center;
  background-size: 34px;
}

.menulist ul li a.menu13:hover,
.menulist ul li.menu13.active {
  background: #ec3525 url(../../images/menu13.png) no-repeat 25px center;
  background-size: 23px;
}

.menulist ul li a.menu14:hover,
.menulist ul li.menu14.active {
  background: #ec3525 url(../../images/menu14.png) no-repeat 25px center;
  background-size: 30px;
}

.menulist ul li a.menu15:hover,
.menulist ul li.menu15.active {
  background: #ec3525 url(../../images/menu15.png) no-repeat 25px center;
  background-size: 27px;
}

.menulist ul li a.menu16:hover,
.menulist ul li.menu16.active {
  background: #ec3525 url(../../images/menu16.png) no-repeat 25px center;
  background-size: 22px;
}

.dashboard-main-content {
  padding: 30px 24px 0;
  width: 100%;
  background: #2c2c2c;
}

.dashboard-head {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: sticky;
  top: 0;
  background: #2c2c2c;
  z-index: 9;
}

.teams-select {
  width: 260px;
}

.teams-select select {
  width: 100%;
  background: #484848;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  height: 60px;
  border: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  outline: 0;
  background: #484848 url(../../images/select-dropdown.png) no-repeat 93% center;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 0 25px;
}

.teams-select1 select {
  width: 100%;
  margin-left: 342%;
  background: #484848;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 60px;
  border: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  outline: 0;
  background: #484848 url(../../images/select-dropdown.png) no-repeat 93% center;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 0 25px;
}

.teams-select3 {
  width: 200px;
  margin-left: 1rem;
}

.teams-select3 select {
  width: 100%;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 60px;
  border: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  outline: 0;
  background: #484848 url(../../images/select-dropdown.png) no-repeat 93% center;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 0 25px;
}

.create-teams {
  margin: 0;
  padding: 0;
  margin-left: 20px;
}

.create-teams button {
  width: 215px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-size: 16px;
  color: #ec3525;
  border: none;
  outline: 0;
  padding: 0;
}

.create-teams button img {
  max-width: 100%;
  width: 28px;
}

.login-account {
  margin-left: auto;
}

.login-account ul {
  margin: 0;
  padding: 0;
}

.login-account ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
  border-left: 1px solid #fff;
  line-height: 15px;
}

.login-account ul li:first-child {
  border: none;
}

.login-account ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 0 10px;
}

.profile-head {
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
}

.profile-head-img img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  background: #f45648;
}

.profile-head-name {
  font-size: 16px;
  color: #fff;
  width: 100%;
  font-weight: 500;
  padding-right: 12px;
}

.three-dots {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
}

.three-dots span {
  display: block;
  width: 4px;
  height: 4px;
  background: #c9c9c9;
  border-radius: 50%;
  margin: 0 2px;
}

.dashboard-top-content {
  display: flex;
  padding-top: 8px;
}

.dashboard-top-content-left {
  width: 52%;
}

.team-profile {
  width: 40%;
  background: #595959;
  /*    box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);*/
  border-radius: 20px;
  padding: 44px 20px 30px;
}

.team-profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  background: #f45648;
}

.team-profile-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 5px solid #fff;
  border-radius: 50%;
}

.logo {
  padding: 0 15px;
}

.logo img {
  max-width: 100%;
  width: 210px;
}

.team-profile-name {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  margin-top: 15px;
}

.update-team-photo {
  background: #ec3525;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  height: 50px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin-top: 40px;
  position: relative;
}

.update-team-photo input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
}

.dashboard-top-content-left-top {
  display: flex;
  justify-content: space-between;
}

.invoice-due {
  width: 55%;
  margin-left: 5%;
  background: #484848;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.invoice-due h2 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.invoice-due p {
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.invoice-due span {
  font-size: 14px;
  line-height: 18px;
  color: #959595;
  margin: 0;
  padding: 0;
}

.invoice-icon {
  margin: 25px 0;
}

.dublin-weather {
  background: #484848;
  border-radius: 15px;
  padding: 35px 25px;
}

.dublin-weather h2 {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  margin: 0 0 25px 0;
  padding: 0;
}

.dublin-weather-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dublin-weather-main-inner {
  display: flex;
  align-items: center;
}

.dublin-weather-main-inner-text {
  padding-left: 30px;
}

.dublin-weather-main-inner-text h4 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin: 0 0 5px 0;
  padding: 0;
}

.dublin-weather-main-inner-text h5 {
  font-weight: 500;
  font-size: 25px;
  color: #ff9d04;
  margin: 0 0 5px 0;
  padding: 0;
}

.dublin-weather-main-inner-text span {
  font-weight: normal;
  font-size: 14px;
  color: #adadad;
  margin: 0;
  padding: 0;
  display: block;
}

.dublin-weather-main-inner-img img {
  width: 82px;
}

.dublin-weather-bottom {
  display: flex;
  justify-content: space-between;
}

.dublin-weather-bottom-boxes {
  width: 23%;
  background: #595959;
  border-radius: 15px;
  text-align: center;
  padding: 15px 10px 10px;
  min-height: 154px;
}

.dublin-weather-bottom-boxes h3 {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.dublin-weather-bottom-boxes img {
  width: 55px;
  margin: 4px 0 12px;
}

.dublin-weather-bottom-boxes h6 {
  color: #646464;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.dublin-weather-bottom-boxes h6 span {
  color: #adadad;
}

.active-degree span {
  display: block;
  font-weight: 500;
  font-size: 28px;
  color: #ff9d04;
  line-height: 28px;
}

.active-degree p {
  font-size: 14px;
  color: #adadad;
  margin: 0;
  padding: 0;
}

.dashboard-top-content-right {
  width: 45.5%;
  margin-left: 2.5%;
  background: #484848;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.team-list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}

.team-list-head h2 {
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  margin: 0;
  padding: 0;
}

.team-list-head a {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 30px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
}

.team-list-section {
  padding: 0px 15px 22px;
}

.team-list-box {
  position: relative;
}

.team-list-box-img {
  width: 90px;
}

.team-list-box-img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 5px solid #fff;
  overflow: hidden;
  object-fit: cover;
}

.team-list-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: #595959;
  border-radius: 15px;
  padding: 13px 15px;
}

.team-list-box:last-child {
  margin-bottom: 0;
}

.team-list-box-text {
  width: 100%;
  padding-left: 15px;
  position: relative;
}

.team-list-box-text h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.team-list-box-text h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: #9e9e9e;
  margin: 8px 0 0 0;
  padding: 0;
}

.team-list-box-text h5 span {
  margin-left: 15px;
}

.team-list-box-text a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ec3525;
  margin: 7px 0 0 0;
  padding: 0;
  display: block;
  text-decoration: none;
}

.player-schedule-section {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.dashboard-top-content-left-bottom {
  margin-top: 30px;
  border-radius: 15px;
  overflow: hidden;
}

.dashboard-schedule-section {
  background: #484848;

  border-radius: 20px;
  width: 45.5%;
}

.dashboard-schedule-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}

.dashboard-schedule-head h2 {
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  margin: 0;
  padding: 0;
}

.dashboard-schedule-head a {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 25px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
}

.dashboard-schedule-main-box {
  padding: 0 25px;
}

.dashboard-schedule-main-box-option {
  display: flex;
}

/*custom radio*/
.options-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 50px;
}

/* Hide the browser's default checkbox */
.options-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 26px;
  width: 26px;
  border: 2px solid #ec3525;
  border-radius: 50%;
  background: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.options-radio input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.options-radio .checkmark:after {
  left: 3px;
  top: 3px;
  width: 16px;
  height: 16px;
  background: #ec3525;
  border-radius: 50%;
}

/*custom radio*/

.dashboard-schedule-game-event-text h2 {
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  margin: 0 0 20px 0;
  padding: 0;
}

.dashboard-schedule-game-event-text ul {
  margin: 0;
  padding: 0;
}

.dashboard-schedule-game-event-text ul li {
  list-style-type: none;
  margin: 0 0 18px 0;
  padding: 0;
}

.dashboard-schedule-game-event-text ul li a {
  font-size: 18px;
  color: #434343;
  text-decoration: none;
  background: url(../../images/right-arrow.png) no-repeat right center;
  padding-right: 30px;
}

.reminder-btn {
  width: 330px;
  height: 55px;
  background: #f8f8fa;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-weight: 500;
  font-size: 16px;
  color: #ec3525;
  border: none;
  padding: 0;
  margin-top: auto;
  outline: 0;
}

.dashboard-schedule-game-event {
  display: flex;
  margin-top: 15px;
}

.dashboard-schedule-game-event-calender {
  width: 100%;
  padding-bottom: 30px;
}

.dashboard-schedule-game-event-calender img {
  width: 100%;
}

.record-standing_section {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.record-standing-box {
  background: #484848;
  border-radius: 20px;
  width: 52%;
  padding: 30px 25px 30px;
}

.record-standing-head {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}

.record-save-box-inner {
  background: #595959;
  padding: 30px 20px;
  border-radius: 20px;
  margin-top: 20px;
}

.record-main-top {
  margin: 0;
  padding: 0;
}

.record-main-top h4 {
  font-weight: 500;
  font-size: 25px;
  color: #ffffff;
  margin: 35px 0 0 0;
  padding: 0;
}

.record-main-top span {
  font-weight: normal;
  font-size: 20px;
  color: #c4c4c4;
}

.record-main-top button {
  border: none;
  width: 106px;
  height: 32px;
  font-weight: normal;
  font-size: 14px;
  background: #ff9900;
  color: #ffffff;
  border-radius: 18px;
}

.record-line-bar {
  margin-top: 42px;
}

.record-line-bar img {
  width: 100%;
}

.enter-result {
  margin-top: 98px;
}

.enter-result-btn {
  background: #ec3525;
  border-radius: 25px;
  border: none;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  width: 151px;
  height: 50px;
  outline: 0;
}

.standing-table {
  border-radius: 15px;
  overflow: hidden;
  background: #595959;
  margin-top: 20px;
  padding-bottom: 15px;
}

.standing-table table {
  width: 100%;

  overflow: hidden;
}

.standing-table table tr th {
  padding: 25px 15px;
  border-bottom: 1px solid #484848;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
}

.standing-table table tr th,
.standing-table table tr td {
  text-align: center;
}

.standing-table table tr th:first-child,
.standing-table table tr td:first-child {
  text-align: left;
}

.standing-table table tr td {
  padding: 12px 15px;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}

.record-save-section {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.record-save-box {
  background: #484848;
  border-radius: 20px;
  width: 52%;
  padding: 30px 25px 30px;
}

.save-travel {
  background: #484848;
  border-radius: 20px;
  width: 45.5%;
  width: 45.5%;
  padding: 30px 25px 30px;
}

.save-on-travel-img {
  margin-bottom: 15px;
}

.save-on-travel h4 {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.save-on-travel p {
  font-weight: normal;
  font-size: 16px;
  color: #969696;
  margin: 14px 0 0 0;
  padding: 0;
  line-height: 25px;
}

.save-on-travel a {
  width: 214px;
  height: 50px;
  line-height: 50px;
  background: #ec3525;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  font-size: 16px;
  font-weight: normal;
  color: #ffff;
  display: block;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
}

.myteam-teamsnap-section {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.myteam-list-section {
  margin-top: 20px;
}

.myteam-teamsnap-section .team-list-box-img img {
  border-width: 2px;
}

.myteam-teamsnap-section .my-team-details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.myteam-teamsnap-section .my-team-details .name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ec3525;
}

.myteam-teamsnap-section .my-team-details .category,
.myteam-teamsnap-section .my-team-details .season {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #b0b0b0;
}

.teamsnap-section {
  background: #484848;
  border-radius: 20px;
  width: 45.5%;
}

.teamsnap-section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}

.teamsnap-section-head h2 {
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  margin: 0;
  padding: 0;
}

.teamsnap-section-head a {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 25px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
}

.teamsnap-section-main {
  padding: 0px 25px 30px;
}

.teamsnap-list-box {
  margin-bottom: 15px;
  background: #595959;
  border-radius: 15px;
  padding: 18px 15px;
}

.teamsnap-list-box:last-child {
  margin-bottom: 0;
}

.teamsnap-list-box p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.teamsnap-list-box span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #b0b0b0;
  margin: 0;
  padding: 0;
}

.footer {
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.footer .copy-right {
  align-items: center;
  font-size: 15px;
  color: #7b7b7b;
}

.footer .copy-right a {
  color: #7b7b7b;
  text-decoration: none;
}

.social-icon-footer p {
  font-size: 15px;
  color: #555555;
  margin: 0;
  padding: 10px;
}

.social-icon-footer p a {
  margin: 0 5px;
}

.custom-modal .modal-content {
  background: #424242;
  border-radius: 35px;
}

.custom-modal .modal-content .form-control {
  background: #424242;
  color: #fff;
}

.custom-modal .modal-content label {
  color: #fff;
}

.custom-modal .close {
  position: absolute;
  right: 40px;
  top: 32px;
  opacity: 1;
  padding: 0;
  outline: 0;
}

.custom-modal .close img {
  width: 25px;
  height: 25px;
}

.custom-modal .modal-header {
  justify-content: center;
  border: none;
}

.custom-modal .modal-title {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
}

.custom-modal .modal-body {
  padding: 1rem 2rem 2rem 2rem;
}

.btn-set {
  text-align: center;
  margin-top: 2rem;
}

.custom-modal .btn-deflt {
  background: #ec3525;
  border-radius: 25px;
  padding: 0 30px;
  height: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
}

/*team-shop-page*/

.team-shop-page {
  margin-top: 10px;
}

.my-order-section {
  margin: 0;
  padding: 0;
  text-align: right;
}

.my-order-section a {
  width: 151px;
  height: 50px;
  background: #ec3525;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  border-radius: 25px;
  display: block;
  text-decoration: none;
  text-align: center;
  margin-left: auto;
}

.team-shop-list-box {
  padding: 30px 25px;
  background: #484848;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-top: 35px;
}

.sort-by-section {
  display: flex;
}

.sort-by-section-main {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.sort-by-section-main select {
  width: 176px;
  background: #595959;
  border-radius: 25px;
  height: 50px;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  background: #595959 url(../../images/select-dropdown.png) no-repeat 92% center;
  font-weight: normal;
  font-size: 16px;
  color: #d3d3d3;
  padding: 0 24px;
}

.sort-by-section-main label {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: #ffffff;
  margin: 0 12px 0 0;
  padding: 0;
}

.team-shop-list-main {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5%;
}
.team-shop-list-main .product-action {
  display: flex;
  gap: 15px;
  margin: 15px 0 0 0;
  justify-content: flex-end;
}
.team-shop-list-main:after {
  content: "";
  flex: 1;
}


.team-shop-product-box {
  width: 32.33%;
  background: #595959;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 1.5%;
  position: relative;
}

.team-shop-list-main .team-shop-product-img img {
  width: 347px;
  height: 347px;
  object-fit: contain;
  max-width: inherit;
  margin: auto;
  display: block;
}
.team-shop-list-main .team-shop-product-img img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  background: #fff;
  border-radius: 10px;
}
.team-shop-list-main .team-shop-product-img img.dummy-img { object-fit: scale-down;}

.team-shop-product-text {
  margin-top: 35px;
}

.team-shop-product-text h2.product-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.team-shop-product-text p.product-description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #d5d5d5;
  margin: 15px 0 0 0;
  padding: 0;
}

.team-shop-product-text .product-price {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ec3525;
}

.team-shop-product-text .product-price {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ec3525;
  margin-top: 14px;
}

.team-shop-product-text .product-action {
  margin-left: auto;
}

.team-shop-product-text .product-action .edit-icon {
  filter: invert(75%) sepia(85%) saturate(3509%) hue-rotate(352deg) brightness(102%) contrast(90%);
  margin-right: 0.5rem;
}

.team-shop-product-text .product-size {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #d5d5d5;
  margin-top: 9px;
}

/*team-shop-page*/

/*prefarance-page*/
.prefarance-page {
  min-height: 900px;
}

.page-title {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
  margin: 10px 0 0 0;
  padding: 0;
}

.prefarance-box {
  overflow-y: auto;
  margin-top: 0;
  background: #484848;
  box-shadow: 0px 4px 7px 3px rgb(0 0 0 / 5%);
  border-radius: 0;
  display: flex;
  min-height: 550px;
  height: 100vh;
}

.nav-link {
  color: white;
}

.nav-tabs .nav-link.active {

  background-color: #ec3525 !important;
  color: #fff !important;
  border: none !important;
}

.nav-tabs .nav-link:hover {
  border: none !important;
  color: white !important;
}

.nav-tabs .nav-link {
  width: 25%;
  text-align: center;
  flex: 1;
}

.main-wrapper-prefarance-box {
  margin-top: 0;
  background: #484848;
  box-shadow: 0px 4px 7px 3px rgb(0 0 0 / 5%);
  border-radius: 20px
}

.prefarance-box ul.nav-tabs {
  width: 28%;
  border-right: 1px solid #2c2c2c;
  padding: 30px 30px;
  flex-direction: column;
  border-bottom: none;
}

.prefarance-box .nav-tabs .nav-link {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.prefarance-box .nav-tabs .nav-item {
  margin-bottom: 20px;
}

.prefarance-box .nav-tabs .nav-item.show .nav-link,
.prefarance-box .nav-tabs .nav-link.active {
  background: none;
  color: #ff9900;
  border: none;
}

.prefarance-box .nav-tabs .nav-link:focus,
.prefarance-box .nav-tabs .nav-link:hover {
  border: none;
  color: #ff9900;
}

.prefarance-box .tab-content {
  width: 72%;
  padding: 36px 30px;
}

.prefarance-tab-content h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.prefarance-form-list {
  margin: 20px 0 0 0;
  padding: 0;
}

.prefarance-form-list label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 15px 0;
  padding: 0;
}

.prefarance-form-list-box {
  display: flex;
  align-items: center;
}

.prefarance-form-list-box .input-select {
  width: 55%;
  /* background: ; */
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  height: 60px;
  outline: 0;
  padding: 0 25px;
  font-size: 15px;
  color: #a7a7a7;
  background: #484848 url(../../images/select-dropdown.png) no-repeat 95% center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.prefarance-form-list-box p {
  width: 45%;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  padding-left: 20px;
}

.social-share p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 156.4%;
  color: #a7a7a7;
  margin: 20px 0 0 0;
  padding: 0;
}

.social-share p a {
  text-decoration: none;
  color: #ff9900;
  font-weight: 500;
}

.social-share a.connect-toif {
  width: 206px;
  height: 50px;
  background: #ec3525;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  display: block;
  line-height: 50px;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  margin-top: 40px;
}

/*prefarance-page*/

/*liability-waiver*/
.liability-box {
  width: 900px;
  margin: 30px auto 50px auto;
}

.player-details-section {
  display: flex;
  justify-content: space-between;
}

.player-details-box span {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  display: block;
}

.player-details-box p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.pdf-image {
  margin-top: 50px;
}

.pdf-image img {
  width: 100%;
}

.liability-form {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.liability-form-input {
  width: 40%;
  margin-bottom: 25px;
}

.liability-form-input label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: block;
  color: #ffffff;
  margin: 0 0 15px 0;
  padding: 0;
}

.liability-form-input .liability-input-field {
  width: 100%;
  height: 58px;
  background: #484848;
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  outline: 0;
  padding: 0 15px;
}

.team-payment {
  margin: 60px 0 0 0;
  padding: 0;
  width: 100%;
}

/*liability-waiver*/

/*team-payment*/
.team-payment table {
  width: 100%;
}

.team-payment table tr {
  border-bottom: 1px solid #2c2c2c;
}

.team-payment table tr:first-child,
.team-payment table tr:last-child {
  border: none;
}

.team-payment table tr td,
.team-payment table tr th {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 22px 25px;
}

.profile-name-img img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  margin-right: 10px;
}

.team-payment table tr td a.view-invoice {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
}

.team-payment table tr td a.pay-now {
  font-weight: 500;
  font-size: 16px;
  color: #ff9900;
  text-decoration: none;
}

.team-payment table tr td span.paid {
  color: #979797;
}

.invoice-show .modal-body {
  padding: 0;
}

.invoice-show .modal-body .invoice-img {
  padding-top: 65px;
}

.invoice-show .modal-body .invoice-img img {
  width: 100%;
}

.invoice-download {
  width: 130px;
  height: 45px;
  background: #ec3525;
  box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  color: #fff;
  outline: 0;
}

/*team-payment*/

/*player-assesment*/
.flag-prac .practice {
  width: 115px;
  height: 35px;
  border: 1px solid #fff;
  border-radius: 7px;
  background: #595959;
  font-size: 15px;
  color: #fff;
}

.flag-prac {
  display: flex;
  margin-bottom: 15px;
}

.flag-prac img {
  margin-right: 15px;
}

.game-name {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  color: #ff9900;
}

.team-assesment table tr td,
.team-assesment table tr th {
  padding: 25px 18px;
  font-size: 16px;
  vertical-align: top;
}

.team-assesment table tr td span {
  color: #9e9e9e;
}

.team-assesment .add-btn {
  text-align: center;
  margin-top: 10px;
}

.team-assesment .add-btn button {
  padding: 0;
  border: none;
  background: none;
}

.last-row {
  display: flex;
  align-items: center;
}

.last-row p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.last-row button {
  border: none;
  background: none;
  padding: 0;
  margin-left: 10px;
}

.team-assesment table tr td:first-child,
.team-assesment table tr th:first-child {
  width: 21%;
}

.team-assesment table tr td:nth-child(4n),
.team-assesment table tr th:nth-child(4n) {
  width: 20%;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-header .add-new-btn {
  width: 136px;
  height: 50px;
  background: #ec3525;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 50px;
  display: block;
  border-radius: 25px;
  margin-right: 15px;
}

.page-header .view-preferance {
  width: 178px;
  height: 50px;
  background: #484848;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  line-height: 50px;
  display: block;
  border-radius: 25px;
}

.right-head-buttons {
  display: flex;
  align-items: center;
}

.assesment-modal .modal-content {
  padding: 0 50px;
}

.assesment-modal .modal-content .form-control {
  background: #484848;
  border: 1px solid #2c2c2c;
  border-radius: 6px;
  height: 50px;
}

.assesment-modal .modal-content label {
  text-align: center;
  display: block;
}

.cancel-save-btn-set {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-save-btn-set .btn-deflt {
  width: 130px;
  margin: 0 10px;
}

.cancel-save-btn-set .btn-deflt.cancel {
  background: #8a8a8a;
}

.save-create-btn {
  margin-top: 25px;
  text-align: center;
}

#assignmentpreferance .modal-content {
  padding-top: 30px;
  padding-bottom: 30px;
}

#assignmentpreferance .modal-body {
  text-align: center;
}

/* Customize the label (the container) */
#assignmentpreferance .hide-game-check {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.hide-game-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.hide-game-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background: #353535;
  border-radius: 4px;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.hide-game-check input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.hide-game-check .checkmark:after {
  left: 9px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.delect-assment-modal {
  text-align: center;
}

.delect-assment-modal p {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.delect-assment-modal h5 {
  font-size: 16px;
  color: #ffffff;
  margin: 25px 0 20px 0;
  padding: 0;
  font-weight: 600;
}

/*player-assesment*/

/*Player team Roaster*/

.team-roaser-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-roaser-head .team-roaser-head-right select {
  width: 178px;
  height: 50px;
  background: #484848;
  border-radius: 25px;
  color: #ffffff;
  font-size: 16px;
  padding: 0 20px;
  border: none;
  outline: 0;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background: #484848 url(../../images/select-dropdown.png) no-repeat 93% center;
  background-size: 14px;
}

.team-roaser-head-right button.team-roaser-head-btn {
  width: 232px;
  height: 50px;
  border-radius: 25px;
  background: #ec3525;
  border: none;
  font-size: 16px;
  color: #fff;
  padding: 0;
  margin-left: 15px;
}

.team-roaster-table {
  display: block;
}

.team-roaster-table-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 30px;
  border-bottom: 1px solid #2c2c2c;
}

.team-roaster-table-head p {
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.team-roaster-table-head span {
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.team-roaster-table .team-payment {
  margin-top: 0;
}

.team-roaster-table .profile-name-icon {
  display: flex;
  align-items: center;
}

.team-roaster-table .profile-name-icon img.table-profile {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  margin-right: 10px;
}

.team-roaster-table .mic-account {
  margin-left: 10px;
}

.team-roaster-table .mic-account h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 0 0 8px 0;
  background: url(../../images/tick-circle.png) no-repeat right center;
  padding-right: 25px;
}

.team-roaster-table .contact-info h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.team-roaster-table .contact-info span {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #9e9e9e;
  margin: 0;
  padding: 0;
}

.team-roaster-table .account-icon {
  margin-left: 10px;
}

.team-roaster-table-bottom {
  margin-top: 40px;
  min-height: 350px;
}

/*player info*/
.player-info-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player-info-head .player-info-head-right button.edit-btn {
  width: 106px;
  height: 50px;
  border-radius: 25px;
  background: #484848;
  font-size: 16px;
  color: #fff;
  border: none;
  outline: 0;
}

.player-info-head .player-info-head-right button.add-new-family {
  width: 267px;
  height: 50px;
  border-radius: 25px;
  background: #ec3525;
  font-size: 16px;
  color: #fff;
  border: none;
  outline: 0;
  margin-left: 12px;
}

.prefarance-box.player-info ul.nav-tabs {
  padding: 30px 20px;
}

.prefarance-form-list label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 15px 0;
  padding: 0;
  display: block;
}

.prefarance-form-list-box {
  display: flex;
  align-items: center;
}

.playerinfo-form .input-select {
  width: 100%;
  border: 1px solid #2c2c2c;
  border-radius: 10px;
  height: 60px;
  outline: 0;
  padding: 0 25px;
  font-size: 15px;
  color: #a7a7a7;
  background: #424242;
}

.player-profile-img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
}

button.add-links {
  width: 158px;
  height: 50px;
  background: #ec3525;
  font-size: 16px;
  border-radius: 25px;
  color: #fff;
  border: none;
  outline: 0;
}

.react-datepicker__input-container {
  position: relative !important;
  display: grid !important;
  width: 132%;
  margin-left: 33% !important;
  height: 50px !important;
  border-radius: 10px !important;
}

.react-datepicker__input-container input {
  background-color: #484848;
  border: aliceblue;
}

.react-datepicker__input-container input.abc {
  margin-left: 35% !important;
  border-radius: 10px;
  width: 164% !important;
  background-color: white;
  border: 0.5px solid rgb(12, 11, 11);
}

.react-date-picker {
  margin-left: -37% !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  margin-left: -99px !important;
}

.react-time-picker__wrapper {
  display: flex !important;
  /* flex-grow: 1; */
  flex-shrink: 0 !important;
  border: thin solid gray;
  width: 220% !important;
  height: 57px !important;
  margin-left: 6px !important;
  border-radius: 10px !important;
  border: aliceblue !important;
}

.react-time-picker__wrapper.bcd {
  margin-left: -66% !important;
}

/* .modal-content {
  position: absolute !important;
  top: 80px !important;
  width: 120%;
  text-align: center;
  border-radius: 3.3rem !important;
  background-color: #2C2C2C !important no need position;
} */
.modal-open .modal {
  overflow-x: auto;
  overflow-y: auto;
}

/*player info*/

span {
  color: white;
  padding: 5px;
}

.fileStyle {
  color: white;
  border: 1px solid black;
  width: 150px;
  text-align: center;
  padding: 10px;
  border-radius: 24px;
  margin-bottom: 10px;
}

.team-assesment table tr td:first-child,
.team-assesment table tr th:first-child {
  width: 7% !important;
}

.team-assesment table tr th:first-child {
  width: 25% !important;
}

.sizebox {
  margin: 10px;
  background-color: white;
  height: 37px;
  width: 49px;
  text-align: center;
  padding-top: 7px;
  border-radius: 5px;
}

.Addcart {
  width: 158px;
  height: 50px;
  background: #ec3525;
  font-size: 16px;
  border-radius: 25px;
  color: #fff;
  border: none;
  outline: 0;
  margin-top: 29%;
}

.imgSize {
  height: 76px;
  width: 85px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 7px;
  margin: 5px;
}

.EditPhoto {
  color: white;
  border: 1px solid;
  text-align: center;
  font-weight: bolder;
  border-radius: 37px;
  height: 49px;
  width: 118px;
  padding-top: 10px;
  margin-top: 46px;
}

.PhoneStyle {
  border-bottom: 1px solid gray;
  border-top: 1px solid gray;
  margin-top: 15px;
  padding-bottom: 16px;
}

.fileChoosen {
  text-align: left;
  padding-top: 0.5rem;
  color: #9f9f9f;
  font-weight: 300;
  font-size: 1rem;
}

.fileBox {
  margin-top: 45px;
  border: 1px solid gray;
  border-radius: 12px;
  padding: 20px;
  background-color: #2c2c2c;
}

.fileBox span {
  margin-bottom: 1rem;
  display: inline-block;
}

.fileBox .custom-file-label {
  border: 1px solid #484848;
  background: transparent;
  color: #fffbfb;
  cursor: pointer;
}

.fileBox .custom-file-label::after {
  color: #bebebe;
  background-color: #47484a;
}

.managerDropdownLink {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  padding: 18px 25px;
  border-radius: 10px;
  margin-top: 22px;
}

.managerDropdownLink .dropdown-item {
  color: #fff !important;
}

.managerDropdownLink .dropdown-item:focus,
.managerDropdownLink .dropdown-item:hover {
  color: #fff !important;
  background: #ec3525;
}

.managerDropdownLink .dropdown-menu a:hover {
  text-decoration: none;
}

.managerDropdownLink .dropdown {
  margin: 0 0.5rem;
}

.react-calendar__navigation__label {
  background-color: #484848 !important;
}

.react-calendar__navigation__arrow {
  background-color: #484848 !important;
}

.react-calendar__tile {
  background-color: #484848 !important;
}

.react-calendar {
  width: 100% !important;
}

.react-calendar {
  border: none !important;
}

.react-calendar__month-view__weekdays {
  background-color: #484848 !important;
}

.react-calendar__viewContainer {
  margin-top: -17px !important;
}

.e-control {
  height: 350px !important;
  /* padding: 7px !important; */
  border-radius: 20px !important;
  /* margin-top: 33px !important;  */
  /* padding-top: 23px !important; */
  max-width: 500px !important;
  margin-left: 8px;
}

.donutchart {
  width: 100%;
}

.dashboard-container select.form-control {
  color: rgb(255, 255, 255);
  background: rgb(72, 72, 72) url("../../images/accr.png") no-repeat 98% center;
  padding: 10px;
  margin-left: 10px;
  border-radius: 10px;
  height: auto !important;
}

/* ---------Accordian-Start------------- */
.accorDianbg {
  width: 100%;
}

.accordion {
  margin-top: 40px;
}

.accordion .card {
  border: none;
  margin-bottom: 20px;
}

.accordion .card h2 {
  background: #484848 url(./../../images/accrh.png) no-repeat 93% center;
  background-size: 20px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}

.accordion .card h2.collapsed {
  background: #484848 url(./../../images/accr.png) no-repeat 93% center;
}

.accordion .card-body {
  padding-left: 0;
  padding-right: 0;
}

.accorDianbg .card {
  background-color: transparent;
}

.accorDianbg h2 {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff;
}

.accorDianbg p {
  cursor: default;
  color: #fff;
  font-size: 15px;
  margin-bottom: 10px;
}

.accorDianbg p:hover {
  color: #ff9900;
}

.accorDianbg .card-body {
  padding-left: 25px;
  padding-bottom: 0;
}

.accorDianbg h2[aria-expanded="true"] {
  color: #ff9900;
}

/* ---------Accordian-End------------- */

.Going {
  background-color: "blue";
}

.Going1 {
  background-color: "green";
}

:target {
  color: red;
}

:target {
  color: red;
}

:target {
  color: red;
}

.focus1:focus {
  background-color: green;
}

.focus2:focus {
  background-color: gray;
}

.focus3:focus {
  background-color: red;
}

.calendar-wrap.e-calendar {
  background: #595959;
  margin: 0;
  max-width: inherit !important;
}

.calendar-wrap.e-calendar .e-header .e-title {
  color: #d0d0d0;
}

.calendar-wrap.e-calendar th,
.calendar-wrap.e-bigger.e-small .e-calendar th {
  color: rgb(239 235 235 / 54%);
}

.calendar-wrap.e-calendar .e-content span {
  color: #fff;
}

.calendar-wrap.e-calendar .e-footer-container {
  background: transparent;
}

.calendar-wrap.e-calendar .e-header span {
  color: #fff;
}

.calendar-wrap.e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: #ec3525;
  border: 1px solid #ec3525;
  color: #fff;
}

.calendar-wrap .e-btn.e-primary,
.calendar-wrap .e-css.e-btn.e-primary {
  color: #ec3525 !important;
}

.prefarance-form .m-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  letter-spacing: 0em;
  text-align: center;
  color: #ec3525;
}

.modal .modal-content {
  background-color: #424242;
  border-radius: 1.5rem;
}

.modal .modal-content .modal-body {
  padding: 1.85rem;
}

.plr_dtls_wrp .plr_dtls_itm {
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid #474747;
  padding-bottom: 0.5rem;
}

.plr_dtls_wrp .plr_dtls_itm label {
  font-weight: 600;
  flex: 1;
  margin: 0;
}

.plr_dtls_wrp .plr_dtls_itm span {
  flex: 1;
  padding: 0;
}

.assignment_wrp .form-control,
.assignment_wrp .form-control:focus {
  background: #484848;
  color: #fff;
  border: 1px solid #666464;
}

.add-new-form {
  padding: 1rem;
}

.add-new-form .cn-title {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1rem;
}

.add-new-form .cn-subtitle {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
}

.add-new-form .cn-subtext {
  color: #fff;
  font-size: 1rem;
  line-height: 1.75rem;
}

.add-new-form .ins-list {
  margin: 1rem 0;
}

.add-new-form .ins-list li {
  color: #fff;
  font-size: 1rem;
  line-height: 1.75rem;
}

.cs-calendar.rbc-calendar {
  height: 100vh;
}

.cs-calendar.rbc-calendar .rbc-toolbar button,
.cs-calendar.rbc-calendar .rbc-month-view,
.cs-calendar.rbc-calendar .rbc-time-view {
  border: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-timeslot-group {
  border-bottom: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-toolbar button {
  color: #fff;
}

.cs-calendar.rbc-calendar .rbc-toolbar button:hover,
.cs-calendar.rbc-calendar .rbc-toolbar button:focus,
.cs-calendar.rbc-calendar .rbc-toolbar button:active,
.cs-calendar.rbc-calendar .rbc-toolbar button.rbc-active,
.cs-calendar.rbc-calendar .rbc-day-slot .rbc-event {
  background-color: #ec3525;
  border-color: #ec3525;
  color: #fff;
}

.cs-calendar.rbc-calendar .rbc-month-row,
.cs-calendar.rbc-calendar .rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-header {
  border-left: 1px solid #2c2c2c;
  border-bottom: none;
  padding: 10px 5px;
}

.cs-calendar.rbc-calendar .rbc-row .rbc-header:first-child {
  border: none;
}

.cs-calendar.rbc-calendar .rbc-day-bg {
  border-left: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-row-bg .rbc-day-bg:first-child {
  border: none;
}

.cs-calendar.rbc-calendar .rbc-today {
  background-color: #373737;
}

.cs-calendar.rbc-calendar .rbc-off-range-bg {
  background: #666666;
}

.cs-calendar.rbc-calendar .rbc-date-cell {
  padding: 8px;
  text-align: left;
}

.cs-calendar.rbc-calendar .rbc-date-cell.rbc-now .rbc-button-link {
  background-color: #ec3525;
  padding: 2px 5px;
  border-radius: 30px;
}

.cs-calendar.rbc-calendar .rbc-time-content>*+*>* {
  border-left: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-time-content {
  border-top: none;
}

.cs-calendar.rbc-calendar .rbc-time-header-content {
  border-left: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-time-header.rbc-overflowing {
  border-right: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td,
.cs-calendar.rbc-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  border-left: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-agenda-view table.rbc-agenda-table tbody>tr+tr {
  border-top: 1px solid #2c2c2c;
}

.cs-calendar.rbc-calendar .rbc-event,
.cs-calendar.rbc-calendar .rbc-day-slot .rbc-background-event {
  background: transparent;
  outline: none;
}

.cs-calendar.rbc-calendar .title-calendar img {
  width: 20px;
  margin-right: 5px;
}

.cs-calendar.rbc-calendar .title-calendar .t-label {
  border: 1px solid #fff;
  background: #595959;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border-radius: 8px;
  padding: 2px 5px;
}

.dashboard-container .dashboard-side-bar {
  position: relative;
  background: #323131;
  transition: all 0.3s;
}

.dashboard-side-bar .toggle-menu {
  background: #323131;
  position: absolute;
  top: 87px;
  right: -14px;
  color: #fff;
  border-radius: 5px;
  padding: 3px;
  z-index: 9;
}

.dashboard-side-bar .toggle-menu:hover,
.dashboard-side-bar .toggle-menu:active,
.dashboard-side-bar .toggle-menu:focus {
  color: #fff;
  box-shadow: none;
  background: #ec3525;
}

.dashboard-side-bar.menuClose {
  width: 80px;
  min-height: 80px;
}

.dashboard-side-bar.menuClose .menulist ul li {
  font-size: 0;
  transition: inherit;
  padding: 20px 10px 20px 70px;
}

.dashboard-side-bar.menuClose .logo {
  padding: 0;
  overflow: hidden;
}

.dashboard-side-bar.menuClose .logo img {
  max-width: inherit;
  width: 190px;
  margin-left: -10px;
}

.dashboard-container .dashboard-top-content-right {
  width: auto;
  margin-left: 24px;
  flex: 1;
}

.dashboard-container .invoice-due {
  width: auto;
  margin-left: 24px;
  flex: 1;
}

.dashboard-container .dashboard-top-content-left-bottom,
.dashboard-container .player-schedule-section,
.dashboard-container .record-save-section,
.dashboard-container .myteam-teamsnap-section {
  margin-top: 24px;
}

.dashboard-container .dashboard-schedule-section,
.dashboard-container .save-travel,
.dashboard-container .teamsnap-section {
  width: auto;
  margin-left: 24px;
  flex: 1;
}

/* product details */

.product-details-wrap {
  background: #484848;
  border-radius: 1.5rem;
}

.product-details-wrap .top-prod-prt {
  padding: 2rem;
}

.product-details-wrap .top-prod-prt .Addcart.bkbtn {
  margin-top: 0;
  width: auto;
  height: auto;
  padding: 0px 10px;
}

.product-details-wrap .prdimg-lg {
  border-radius: 0.75rem;
}

.product-details-wrap .thumb_img_cont {
  display: flex;
  justify-content: center;
}

.product-details-wrap .thumb_img_cont .imgSize {
  background: #595959;
  border-color: #595959;
}

.product-details-wrap .off-txt {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 1rem;
}

.product-details-wrap .prd-title {
  font-size: 35px;
  font-weight: 500;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 1rem;
}

.product-details-wrap .in-stk-txt {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #3bdb68;
  margin-bottom: 1rem;
}

.product-details-wrap .prd-price {
  font-size: 25px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #ec3525;
  margin-bottom: 1rem;
}

.product-details-wrap .sizetype {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #e7e7e7;
  margin-bottom: 0.5rem;
}

.product-details-wrap .size-chart-wrp {
  background: #595959;
  padding: 1.25rem;
  border-radius: 1.25rem;
  margin-top: 2rem;
}

.product-details-wrap .chrt-head {
  display: flex;
}

.product-details-wrap .chrt-head .sz-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.product-details-wrap .chrt-head .sz-title.txt-color {
  color: #ec3525;
  margin-left: auto;
}

.product-details-wrap .size-chart-wrp .prefarance-form-list {
  margin: 0;
  text-align: right;
}

.product-details-wrap .size-chart-wrp .prefarance-form-list .Addcart {
  margin-top: 42px;
}

.product-details-wrap .size-chart-wrp .sizebox {
  background: #484848;
  color: #000;
  margin: 5px;
}

.product-details-wrap .size-chart-wrp .sizebox.selected {
  background: #ec3525;
  color: #fff;
}

.product-details-wrap .size-chart-wrp .input-select {
  background: #484848;
  border: 1px solid #484848;
  height: 53px;
  color: #fff;
}

.product-details-wrap .size-chart-wrp .prefarance-form-list label {
  text-align: left;
}

.product-details-wrap .accordion {
  margin-top: 0;
}

.product-details-wrap .accordion .card {
  margin-bottom: 0rem;
  color: #fff;
  background: transparent;
}

.product-details-wrap .accordion .card .card-header {
  cursor: pointer;
  border-bottom: 1px solid #3e3e3e;
  margin: 0;
  background: transparent;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
}

.product-details-wrap .accordion .card:last-child .card-header {
  border: none;
}

.product-details-wrap .accordion .card .card-header .accr-icon {
  margin-left: auto;
}

.product-details-wrap .accordion .card ul {
  padding: 0 1rem;
  list-style: none;
}

.product-details-wrap .accordion .card ul li {
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #c1c1c1;
}

.cart_wrapper {
  margin-top: 1rem;
}

.cart_wrapper .card {
  background: #3f3e3e;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.cart_wrapper .card .removeAll {
  color: #fb3322;
  text-align: left;
  display: inline-block;
  max-width: 115px;
  padding: 0;
  outline: none;
  box-shadow: none;
  margin-bottom: 1rem;
}

.cart_wrapper .cart_item {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #585858;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.cart_wrapper .cart_item:last-child {
  border: none;
  padding: 0;
  margin-bottom: 0;
}

.cart_wrapper .cart_item .cart_item_img {
  width: 90px;
  border-radius: 0.5rem;
  height: 90px;
  object-fit: cover;
  object-position: center center;
}

.cart_wrapper .cart_item .cart_item_content_wrp {
  flex: 1;
  margin: 0 1rem;
}

.cart_wrapper .cart_item .crt-itm-nme {
  font-size: 1rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  text-transform: capitalize;
}

.cart_wrapper .cart_item .pdt-size {
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #b9b6b6;
  margin: 0.5rem 0;
}

.cart_wrapper .cart_item .pdt-size .form-control {
  display: inline-block;
  width: auto;
  border-color: #565454;
  background-color: #3f3e3e;
  padding: 0px 1rem;
  border-radius: 0.35rem;
  background-size: 0.75rem;
  box-shadow: none;
  outline: none;
  background-position: 93% center;
}

.cart_wrapper .cart_item .crt-action {
  display: inline-block;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.cart_wrapper .cart_item .crt-action span {
  padding: 0.2rem 0.5rem;
  line-height: 1rem;
  display: inline-block;
}

.cart_wrapper .cart_item .crt-action span.cursor {
  cursor: pointer;
}

.cart_wrapper .cart_item .cart-price-prt {
  text-align: center;
}

.cart_wrapper .cart_item .btn-dlt {
  color: #d1d1d1;
  padding: 0 0.3rem;
  box-shadow: none;
}

.cart_wrapper .cart_item .p-price {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 1rem;
}

.cart_wrapper .price-sum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.cart_wrapper .price-sum span {
  font-size: 1.125rem;
  padding: 0;
}

.cart_wrapper .price-sum .sm-txt {
  font-size: 1rem;
  color: #b9b9b9;
}

.cart_wrapper .cart-proceed {
  background: #ec3525;
  font-size: 1rem;
  border-radius: 1.5rem;
  color: #fff;
  border: none;
  outline: 0;
  padding: 0.5rem 1rem;
}

.cart_wrapper .cart-proceed:hover,
.cart_wrapper .cart-proceed:focus {
  background-color: #eb3527;
  border-color: #eb3527;
}
.team-payment table tr .table_td{
  display: flex;
}

.avatar-check-group {
  position: relative;
}
.avatar-check-group .small-avatar-icon, .small-avatar-icon { width: 50px; height: 50px !important; object-fit: cover; border-radius: 999px;}

.avatar-check-group input[type="checkbox"] { position: absolute; top: 0; left: 0;}



.checkbox-lists-with-action li .action {
  margin-left: auto;
}

.checkbox-lists-with-action li .action .btn {
  padding: 5px;
}

ul.checkbox-lists-with-action {
  padding-right: 0;
  padding-left: 40px;
}


ul.checkbox-lists-with-action li label {
  margin: 3px 0 0 0;
}

.btn.btn-small-primary, .btn.btn-small-secondary {
  padding: 6px 15px;
}

/* new css */
.team-shop-product-box.tspb-folder-box {
   background: transparent !important; padding: 0px 15px;
}
.team-shop-product-box.tspb-folder-box  .team-shop-product-img { position: relative;}
.team-shop-product-box.tspb-folder-box .folder-icon { max-width: 100%; height: auto; width: 100%;}
.team-shop-product-box.tspb-folder-box .team-folder-img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
   width: calc(100% - 30px); padding-top: 18px; overflow: hidden;}
html .team-shop-product-box.tspb-folder-box .team-shop-product-img img {
  width: 100%;
  height: auto;
  object-fit: contain;
  background: transparent;
  border-radius: 10px;
  aspect-ratio: 2/1.3;

}

.team-shop-product-box.tspb-folder-box .team-shop-product-text {
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
}

.team-shop-product-box.tspb-folder-box  .product-description { font-size: 15px; opacity: .8;}

.team-shop-list-main .product-action.floated-top-right {  position: absolute;  top: 0;  right: 0;}
.team-shop-list-main .product-action.floated-top-right {
  position: absolute;
  top: 3px;
  right: 16px;
  background: #ec3525;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  padding: 5px;
}

.team-shop-list-main .product-action.floated-top-right img {
  max-width: 16px;
}

/* .chatbox{
  border: 2px solid grey;
  border-radius: 20px;
  margin: 5px 0;
  padding: 10px;
  background-color: rgb(244, 209, 167);
}

.ccontainer{
  display: flex;
  
}

.ccontainer.me{
  flex-direction: row-reverse;
}

.me .chatbox{
  background-color: cadetblue;
  border-radius: 20px;
} */


/* General container for each message */
.ccontainer {
  display: flex;
  align-items: center; /* Align avatar and message vertically */
  margin-bottom: 10px;
}

/* Profile image styling */
.ccontainer img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0 10px; /* Space between avatar and message */
}

/* Message box styling */
.chatbox {
  border: 2px solid grey;
  border-radius: 20px;
  padding: 10px;
  max-width: 70%; /* Limit the width of message bubbles */
  word-wrap: break-word; /* Ensure text wraps properly */
}

/* Styling for user's messages (me) */
.ccontainer.me {
  flex-direction: row-reverse; /* Reverse direction for user's messages */
}

.ccontainer.me .chatbox {
  background-color: cadetblue; /* Different background color for user's messages */
  color: white;
  border-radius: 20px 20px 0 20px; /* Different shape for user's message */
}

/* Styling for other user's messages */
.ccontainer.other {
  flex-direction: row; /* Normal direction for other user's messages */
}

.ccontainer.other .chatbox {
  background-color: rgb(244, 209, 167); /* Different background color for other user's messages */
  color: black;
  border-radius: 20px 20px 20px 0; /* Different shape for other user's message */
}

.chatbox strong {
  display: block;
  font-weight: bold;
}

.chatbox span {
  display: block;
  margin-top: 5px;
}


.btm input{
  padding: 10px 0;
  flex-grow: 1;
  background-color: #f0f0f0;
  border-radius: 25px;
  width: 10px;
}
.btm button{
  /* padding: 10px 0; */
  border-radius: 25px
}
.btm{
padding-bottom: 15px;
  /* width: 100%; */
  /* bottom: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color:white;
  flex-direction: row;
  margin-bottom: 20px;
}

.send-btn img {
  width: 64px;
    height: 60px;
}
/* .chat-container{
  height: 70vh;
  overflow-y:scroll;
  border: 1px solid black;
  background-color:"#808080"
} */
.contact{
  display: flex;
  flex-direction: column;
}
.contact-container{
  display: flex;
  width: 100%;
  justify-content: space-between;

}