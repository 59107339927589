@charset "utf-8";
/* CSS Document */

@media (max-width: 1440px) {
  .plr_dtls_wrp .plr_dtls_itm {
    font-size: 1.125rem;
  }
  .team-shop-list-main .team-shop-product-img img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    background: #fff;
    border-radius: 10px;
  }
  .team-shop-list-main .team-shop-product-img img.dummy-img { object-fit: scale-down;}
  .dashboard-container .team-list-head {
    padding: 1rem 1.25rem;
  }
  .dashboard-container .invoice-due h2,
  .dashboard-container .team-list-head h2 {
    font-size: 1.25rem;
  }
  .dashboard-container .dublin-weather {
    padding: 1.25rem;
  }
  .dashboard-container .manager-player-section {
    padding: 20px;
    border-radius: 0.85rem;
    flex-wrap: wrap;
  }
  .dashboard-container .manager-player-section h3 {
    font-size: 1.135rem;
  }
  .dashboard-container .teams-select select,
  .dashboard-container .teams-select button.create-new-team,
  .dashboard-container .dropBtn button {
    height: auto;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .dashboard-container .page-header {
    flex-wrap: wrap;
  }
  .product-details-wrap .off-txt {
    font-size: 1rem;
  }
  .product-details-wrap .prd-title {
    font-size: 1.5rem;
    line-height: inherit;
  }
  .teams-select3 select {
    height: 48px;
  }
}

@media (max-width: 992px) {
}
/* 
@media only screen and (max-width: 360px) {
    .dashboard-side-bar{
        width: 54%;
       
    }
    .menulist {
        display: block;
        position: inherit;
        left: 0;
        top: 108px;
        width: 22%;
        background: #2c2c2c;
        z-index: 75;
        padding: 0 10px 0 0;
    }
    
    .teams-select {
        display: grid;
        align-items: center;
        width: auto;
    }
    
    .team-profile-img img {
        width: 86px;
        height: 86px;
        
    }
    .team-profile-img {
        width: 86px;
        height: 86px;
    }
    .teams-select {
    display: grid;
    align-items: center;
    width: auto;
}
    .dashboard-top-content {
    display: block;
    padding-top: 8px;
}
.dashboard-top-content-left-top {
    display: flex;
    justify-content: flex-start;
        width: fit-content;
}
.dashboard-top-content-left-bottom {
        width: fit-content;
}
.team-list-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 32px;
}
.team-list-section {
    width: auto;
}
.player-schedule-section {
    display: block;
}
.record-save-section {
    display: block;
}
} */

@media (max-width: 1199px) {
  .dashboard-container .dashboard-head {
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }
  .dashboard-container .dashboard-head .teams-select {
    width: 100%;
    flex: inherit;
    margin-bottom: 0.5rem;
  }
  .dashboard-container .dashboard-head .profile-head {
    margin-left: inherit;
  }
  .dashboard-container .page-header .streming-head-right {
    flex-wrap: wrap;
  }
  .dashboard-container .page-header .streming-head-right .start-stream-btn {
    margin-top: 10px;
  }
}
@media (max-width: 1024px) {
  .dashboard-side-bar {
    width: 25%;
  }
  .dashboard-top-content-left-top {
    display: block;
    width: 100%;
  }
  .logo {
    text-align: center;
  }
  .nav-header {
    display: block;
    margin-top: 10px;
  }
  .dashboard-main-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  /* .dashboard-side-bar{
        width: 100%;
    } */
  /* .dashboard-head {
    flex-direction: column;
  } */
  .teams-select {
    width: 100%;
  }
  .create-teams {
    margin-left: 0;
  }
  .create-teams button {
    width: 100%;
  }
  .create-teams {
    width: 100%;
    margin-top: 10px;
  }
  .profile-head {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    width: auto;
  }
  /* .dashboard-top-content {
    flex-direction: column;
  } */

  .dashboard-container .dashboard-top-content-left {
    width: 100%;
  }

  .team-profile {
    width: 100%;
  }
  .dublin-weather {
    width: 100%;
    margin-left: 0;
    padding: 20px;
  }
  .dashboard-top-content-right {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }
  .dashboard-schedule-game-event {
    flex-direction: column;
  }
  .dashboard-schedule-game-event-calender {
    width: 100%;
  }
  .dashboard-schedule-game-event-text {
    padding-left: 0;
    width: 100%;
    margin-top: 15px;
  }
  .team-list-section {
    padding: 15px 10px;
  }
  .team-list-box-text h6 {
    font-size: 10px;
  }
  .team-list-box-img {
    width: 40px;
  }
  .team-list-box-img img {
    width: 40px;
    height: 40px;
    border-width: 2px;
  }
  .team-list-box-text h4 {
    font-size: 14px;
    padding-right: 68px;
  }
  .team-list-box-text h5 {
    font-size: 12px;
  }
  .dashboard-schedule-section {
    margin-top: 20px;
  }
  .team-list-head {
    padding: 15px;
  }
  .team-list-head h2 {
    font-size: 16px;
  }
  .team-list-head a {
    font-size: 12px;
    height: 35px;
    line-height: 35px;
  }
  .dashboard-schedule-head h2 {
    font-size: 16px;
  }
  .dashboard-schedule-head a {
    font-size: 13px;
    padding: 0 10px;
  }
  .dashboard-schedule-head {
    padding: 15px;
  }
  .options-radio {
    margin-right: 30px;
  }
  .dashboard-schedule-main-box {
    padding: 15px;
  }
  .dashboard-schedule-game-event-text h2 {
    font-size: 16px;
  }
  .dashboard-schedule-game-event-text ul li a {
    font-size: 12px;
  }
  .reminder-btn {
    width: 100%;
    height: 50px;
    font-size: 12px;
  }
  .options-radio {
    font-size: 14px;
    padding-left: 30px;
  }
  .options-radio .checkmark:after {
    left: 2px;
    top: 2px;
    width: 12px;
    height: 12px;
  }
  .checkmark {
    width: 20px;
    height: 20px;
  }
  .record-standing_section {
    flex-direction: column;
    margin-top: 0px;
  }
  .record-standing-box {
    width: 100%;
    margin-top: 15px;
  }
  .record-standing-head {
    padding: 15px;
    font-size: 16px;
  }
  .record-standing-box-inner {
    padding: 15px;
  }
  .record-main-top-left h4 {
    font-size: 16px;
  }
  .record-main-top-left span {
    font-size: 12px;
  }
  .record-main-top-right button {
    width: 85px;
    height: 30px;
    font-weight: normal;
    font-size: 12px;
  }
  .dashboard-container .standing-table {
    overflow: auto;
  }
  .dashboard-container .standing-table table {
    width: 500px;
  }
  .footer {
    flex-direction: column;
  }
  .footer .copy-right {
    text-align: center;
    font-size: 14px;
  }
  .social-icon-footer {
    margin-top: 15px;
  }

  .nav-header {
    background: #000;
    padding: 8px 15px;
  }
  .nav-header button {
    width: 40px;
    height: 35px;
    border: 1px solid #fff;
    background: none;
    outline: 0;
    border-radius: 4px;
  }
  .nav-header button span {
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;
    background: #fff;
  }
  .nav-header button span:last-child {
    margin-bottom: 0;
  }
  /* .menulist {
    display: none;
    position: absolute;
    left: 0;
    top: 108px;
    width: 100%;
    background: #2c2c2c;
    z-index: 99;
    padding: 0 10px 0 0;
  } */
  .menulist ul li a {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .menulist ul li {
    margin-bottom: 5px;
  }
  .invoice-due {
    width: 100%;
    margin-left: 0;
    padding: 25px;
    margin-top: 20px;
  }
  .dashboard-top-content-left-bottom {
    margin-top: 20px;
  }
  .dublin-weather-bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .player-schedule-section {
    flex-direction: column;
    margin-top: 5px;
  }
  .record-standing-box {
    padding: 20px;
  }
  .record-standing-head {
    padding: 0;
  }
  .record-standing-box-inner {
    padding: 0;
  }
  .dashboard-schedule-section {
    width: 100%;
  }
  .record-save-section {
    flex-direction: column;
    margin-top: 20px;
  }
  .record-save-box {
    width: 100%;
    padding: 15px;
  }
  .enter-result {
    margin-top: 30px;
  }
  .record-main-top h4 {
    font-size: 18px;
  }
  .record-main-top span {
    font-size: 16px;
  }
  .save-travel {
    width: 100%;
    margin-top: 20px;
    padding: 15px;
  }
  .myteam-teamsnap-section {
    flex-direction: column;
  }
  .teamsnap-section {
    width: 100%;
    margin-top: 20px;
    padding: 15px;
  }
  .teamsnap-section-head {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    padding-bottom: 15px;
  }
  .teamsnap-section-head h2 {
    font-size: 15px;
  }
  .teamsnap-section-head a {
    font-size: 12px;
    height: 35px;
    line-height: 35px;
  }
  .teamsnap-section-main {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
  }
  .dublin-weather h2 {
    font-size: 16px;
  }
  .dublin-weather-bottom-boxes h3 {
    font-size: 16px;
  }
  .invoice-due h2 {
    font-size: 18px;
  }
  .team-list-box {
    padding: 8px 10px;
  }
  .team-list-box-text a {
    font-size: 13px;
  }
  .save-on-travel h4 {
    font-size: 16px;
  }
  .record-save-box-inner {
    padding: 20px 10px;
  }
  .save-on-travel p {
    font-size: 14px;
  }
  .save-on-travel a {
    font-size: 14px;
    width: 200px;
  }
  .login-account {
    margin-left: 0;
  }
  .team-shop-list-box {
    padding: 20px 15px;
  }
  .liability-box {
    padding: 15px;
    width: 100%;
  }
  .team-payment {
    overflow: auto;
  }
  .team-payment table {
    width: 1030px;
  }
  .team-roaser-head {
    flex-direction: column;
    justify-content: center;
  }
  .team-roaser-head-right {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
  .team-roaser-head .team-roaser-head-right select {
    width: 100%;
  }
  .team-roaser-head-right button.team-roaser-head-btn {
    margin-left: 0;
    margin-top: 15px;
  }
  .team-roaster-table-head {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 15px;
  }
  .player-info-head-right {
    text-align: center;
  }
  .player-info-head .player-info-head-right button.edit-btn {
    margin-bottom: 10px;
  }
  .update-team-photo {
    height: 15px;
  }
  .dashboard-container .dashboard-top-content {
    flex-wrap: wrap;
  }
  .dashboard-container .team-profile {
    height: auto;
  }
  .dashboard-container .dashboard-top-content-right {
    margin: 24px 0 0 0px;
  }
  .dashboard-container .record-standing-box,
  .dashboard-container .dashboard-schedule-section,
  .dashboard-container .save-travel,
  .dashboard-container .teamsnap-section {
    margin-top: 0;
  }
  .dashboard-container .record-standing-box {
    max-height: 500px;
    overflow-y: auto;
  }
  .modal .modal-content .modal-body {
    padding: 1.7rem;
  }
  .prefarance-form .m-title {
    font-size: 1.5rem;
  }
  .plr_dtls_wrp .plr_dtls_itm {
    font-size: 1rem;
  }
  .dashboard-container .player-info-head {
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .menulist {
    display: contents;
    position: relative;
    left: 0;
    top: 108px;
    width: 100%;
    background: #2c2c2c;
    z-index: 99;
    padding: 0 10px 0 0;
  }
  .teams-select {
    display: grid;
    align-items: center;
    width: auto;
  }
  .dashboard-main {
    display: flex;
    align-content: center;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .dashboard-main-content {
    padding: 30px 24px 0;
    width: 100%;
    background: #2c2c2c;
  }
  .teams-select {
    display: contents;
    align-items: center;
    width: auto;
  }
  .dashboard-top-content-left-top {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: auto;
  }
  .team-profile-img {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    margin-top: -32px;
    margin: 0 auto;
    overflow: hidden;
    background: #f45648;
  }
  .team-profile-img img {
    width: 74px;
    height: 74px;
    object-fit: cover;
    border: 5px solid #fff;
    border-radius: 50%;
  }
  .update-team-photo {
    background: #ec3525;
    box-shadow: 0px 4px 7px 3px rgb(0 0 0 / 5%);
    border-radius: 25px;
    height: 50px;
    cursor: pointer;
    font-weight: 500;
    font-size: 10px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    /* margin-top: -3px; */
    position: relative;
    /* width: 150px;
    margin-left: -18px; */
  }
  .team-profile-name {
    font-weight: 500;
    font-size: 17px;
    color: #ffffff;
    text-align: center;
    margin-top: -15px;
  }
  .team-profile {
    width: 40%;
    height: 346px;
    background: #595959;
    border-radius: 20px;
    padding: 12px 20px 61px;
  }
  .invoice-due .create-new-team-text button {
    background: #ec3525;
    box-shadow: 0px 4px 7px 3px rgb(0 0 0 / 5%);
    border-radius: 25px;
    height: 50px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-top: 2px;
    margin-left: -24px;
    position: relative;
    border: none;
    width: 158px;
    outline: 0;
  }

  .invoice-due {
    width: 55%;
    margin-left: 5%;
    background: #484848;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 0px;
  }
  .teams-select select {
    width: 203px;
    background: #484848;
    box-shadow: 0px 4px 3px rgb(0 0 0 / 10%);
    border-radius: 30px;
    height: 60px;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    outline: 0;
    background: #484848
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MzQ0MzI1RjBBNDIwMTFFQkJCRkVEODMzMTc2N0NBQ0YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MzQ0MzI1RjFBNDIwMTFFQkJCRkVEODMzMTc2N0NBQ0YiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozNDQzMjVFRUE0MjAxMUVCQkJGRUQ4MzMxNzY3Q0FDRiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozNDQzMjVFRkE0MjAxMUVCQkJGRUQ4MzMxNzY3Q0FDRiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsQRv78AAACXSURBVHjaYpg/f77AmjVrAhhIBMj6mHh4eAyYmJjWA0E9KQbw8/PvB+kDsZlCQ0MP/P//vxGIG4gxCGYAkGkA1JMIBB8YYZJr165tYGRkrAfihsDAwEZiDAgODl4AEmdEVoTPIFwGYBiCyyB8BmA1BN2gDx8+TMRnAE5DkA0CMh8AsQIuA/AagmwQPgOIAsBoVyCkBiDAAGedbIrWA263AAAAAElFTkSuQmCC)
      no-repeat 93% center;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    padding: 0 25px;
    margin-right: 10px;
  }
  .dashboard-top-content {
    display: flex;
    padding-top: 8px;
    flex-direction: row;
  }
  .dashboard-top-content-right {
    margin-top: 0;
  }
  .player-schedule-section {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-direction: row;
  }
  .record-save-section {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-direction: row;
  }
  .standing-tb-section {
    background: #484848;
    padding: 30px 25px 30px;
    border-radius: 20px;
    overflow: hidden;
    margin-top: -19px;
  }
  .dashboard-schedule-head a {
    background: #ec3525;
    border-radius: 25px;
    padding: 0px 7px;
    height: 45px;
    font-weight: 400;
    font-size: 0.8rem;
    color: #fff;
    text-align: center;
    line-height: 45px;
    text-decoration: none;
    min-width: inherit;
  }
  .dashboard-schedule-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 13px;
  }
  .myteam-teamsnap-section {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: stretch;
  }
  .teamsnap-section-head a {
    background: #ec3525;
    border-radius: 25px;
    padding: 0 34px;
    height: 45px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 22px !important;
    text-decoration: none;
  }
  .dashboard-schedule-head a {
    background: #ec3525;
    border-radius: 25px;
    padding: 0 25px;
    height: 45px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: center;
    /* line-height: 22px !important; */
    text-decoration: none;
    margin-left: 5px;
  }
}
@media (max-width: 767px) {
  .dashboard-main {
    display: flex;
    align-content: center;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .teams-select {
    display: flex;
    align-items: center;
    width: auto;
    /* flex-direction: column; */
  }
  .dashboard-container .teams-select select {
    width: 185px;
    height: 48px;
  }
  .dashboard-side-bar.menuClose {
    width: 56px;
  }
  .dashboard-container .record-standing-box {
    max-height: inherit;
    overflow-y: inherit;
    width: 100%;
  }
  .dashboard-container .myteam-teamsnap-section {
    flex-wrap: wrap;
  }
  .dashboard-container .invoice-due {
    margin-top: 0;
  }
  .dashboard-container .dashboard-schedule-main-box {
    padding: 0 20px 20px 20px;
  }
  .dashboard-container .record-save-box {
    width: 100%;
  }
  .dashboard-container .dashboard-schedule-section,
  .dashboard-container .save-travel,
  .dashboard-container .teamsnap-section {
    margin: 24px 0 0 0;
    padding: 1.25rem;
  }

  /* .menulist {
    display: block;
    position: absolute;
    left: 0;
    top: 108px;
    width: 24%;
    background: #2c2c2c;
    z-index: 99;
    padding: 0 10px 0 0;
  } */
  .menulist ul {
    margin: 0;
    padding: 0;
  }

  .dashboard-side-bar {
    width: 62%;
    background: #2c2c2c;
    padding: 0px 0px 20px;
  }
  .invoice-due {
    width: fit-content;
    margin-left: 5%;
    background: #484848;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .team-profile {
    width: fit-content;
    background: #595959;

    border-radius: 20px;
    padding: 44px 20px 30px;
  }
  .dashboard-top-content-right {
    width: fit-content;
    margin-left: 2.5%;
    background: #484848;
    box-shadow: 0px 4px 7px 3px rgb(0 0 0 / 5%);
    border-radius: 20px;
  }
  .team-list-section {
    padding: 0px 15px 22px;
    width: fit-content;
  }

  .record-standing-box {
    background: #484848;
    border-radius: 20px;
    width: 80%;
    padding: 30px 25px 30px;
  }
  .dashboard-schedule-section {
    background: #484848;
    border-radius: 20px;
    width: fit-content;
  }
  .dashboard-schedule-head h2 {
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    margin: 0;
    padding: 0;
  }

  .dashboard-schedule-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 26px;
  }
  .record-save-box {
    background: #484848;
    border-radius: 20px;
    width: fit-content;
    padding: 30px 25px 30px;
  }

  .save-travel {
    background: #484848;
    border-radius: 20px;
    width: 45.5%;
    width: 45.5%;
    padding: 30px 25px 30px;
    margin-top: 0;
  }
  .prefarance-box.player-info ul.nav-tabs {
    padding: 30px 20px;
    width: 154px;
  }
  .team-shop-product-box {
    width: 49.33%;
    background: #595959;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 1.5%;
  }
  .record-save-section {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
  }
  .myteam-teamsnap-section {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: stretch;
  }
  .dublin-weather-bottom-boxes {
    width: 49%;
  }
  .dashboard-container .menulist ul li {
    padding: 18px 8px 18px 50px;
    background-size: 24px;
    background-position: 14px center;
  }
  .dashboard-container .menulist ul li.active {
    background-size: 24px;
    padding: 18px 10px 18px 50px;
    background-position: 14px center;
  }
  .dashboard-side-bar.menuClose .menulist ul li {
    padding: 20px 10px 20px 46px;
  }
  .dashboard-container .dublin-weather-bottom-boxes {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media (max-width: 668px) {
  /* side drawer start */
  .dashboard-container .dashboard-side-bar {
    position: fixed;
    z-index: 999;
    top: 0;
    height: 100%;
    width: 75%;
  }
  .dashboard-side-bar.menuOpen {
    left: 0;
  }
  .dashboard-side-bar.menuClose {
    left: -75%;
  }
  .dashboard-side-bar.menuClose .menulist ul li {
    font-size: 14px;
  }
  .dashboard-side-bar .menulist {
    margin-top: 1rem;
  }
  .dashboard-side-bar .toggle-menu {
    top: 1rem;
    right: -1.4rem;
    border-radius: 0 0.5rem 0.5rem 0;
  }
  /* side drawer end */

  .dashboard-container .dashboard-head {
    padding: 0rem;
    flex-wrap: wrap;
  }
  .dashboard-container .dashboard-head .profile-head {
    margin-left: inherit;
    margin-top: 0;
  }
  .dashboard-container .dashboard-head .teams-select {
    width: 100%;
    flex: inherit;
    margin-bottom: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .dashboard-container .teams-select select,
  .dashboard-container .teams-select button.create-new-team {
    width: auto;
    margin-right: 0;
    height: 48px;
    padding: 0.8rem 1rem;
  }
  .dashboard-container .prefarance-box {
    margin-top: 1rem !important;
    flex-wrap: wrap;
    min-height: inherit;
    height: inherit;
    display: inherit;
  }
  .dashboard-container .prefarance-box.player-info ul.nav-tabs {
    width: 100%;
  }
  .dashboard-container .prefarance-box .tab-content {
    width: 100%;
  }
  .dashboard-container .dropBtn button {
    height: 48px;
  }
  .dashboard-container .dashboard-main-content {
    padding: 1rem;
  }
  .dashboard-container .invoice-due {
    margin: 1rem 0 0 0;
  }
  .dashboard-container .dashboard-top-content-left-bottom,
  .dashboard-container .player-schedule-section,
  .dashboard-container .record-save-section,
  .dashboard-container .myteam-teamsnap-section,
  .dashboard-container .dashboard-top-content-right,
  .dashboard-container .dashboard-schedule-section,
  .dashboard-container .save-travel,
  .dashboard-container .teamsnap-section {
    margin-top: 1rem;
  }
  .dashboard-top-content-left-top {
    flex-wrap: wrap;
  }
  .dashboard-container .team-profile {
    width: 100%;
  }
  .dashboard-container .teamsnap-section .teamsnap-section-head {
    padding: 0;
    flex-wrap: wrap;
  }
  .dashboard-container .record-standing-box {
    padding: 1.25rem;
  }
  .dashboard-container .dashboard-schedule-head {
    padding: 0 0 1rem 0;
  }
  .dashboard-container .dashboard-schedule-main-box {
    padding: 0;
  }
  .dashboard-container .teamsnap-section-main {
    padding: 0;
    margin-top: 1rem;
  }
  .dashboard-container .update-team-photo {
    margin-top: 1.25rem;
  }
  .dashboard-container .dublin-weather h2 {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .dashboard-container .record-save-box {
    padding: 1.25rem;
  }
  .dashboard-container .dashboard-schedule-head h2 {
    font-size: 1.25rem;
  }
  .dashboard-container .enter-result {
    margin-top: 30px;
  }
  .dashboard-container .record-main-top h4 {
    font-size: 1.25rem;
    margin: 30px 0 8px 0;
  }
  .dashboard-container .team-list-box-img img {
    width: 80px;
    height: 80px;
  }
  .dashboard-container .player-info-head {
    flex-wrap: wrap;
  }
  .dashboard-container .player-info-head .page-title {
    width: 100%;
    margin-bottom: 1rem;
  }
  .dashboard-container .player-info-head-right {
    width: 100%;
  }
  .dashboard-container
    .player-info-head
    .player-info-head-right
    button.add-new-family,
  .dashboard-container
    .player-info-head
    .player-info-head-right
    button.edit-btn {
    margin: 0.8rem 0;
    width: 100% !important;
    display: block;
    height: auto;
    padding: 10px;
  }
  .dashboard-head {
    position: relative;
  }

  .dashboard-container .dropBtn {
    width: 100%;
  }
  .dashboard-container .teams-select select,
  .dashboard-container .teams-select button.create-new-team,
  .dashboard-container .dropBtn button {
    width: 100%;
    text-align: center;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    height: auto;
    min-height: 48px;
    background-position: 98% center !important;
  }
  .dashboard-container .team-shop-product-box {
    width: 48%;
    margin-bottom: 4%;
  }
  .dashboard-container .prefarance-box ul.nav-tabs {
    width: 100%;
    border: none;
  }
  .dashboard-container .prefarance-form-list-box {
    flex-wrap: wrap;
  }
  .dashboard-container .prefarance-form-list-box .input-select {
    width: 100%;
  }
  .dashboard-container .prefarance-form-list-box p {
    padding: 0;
    width: 100%;
    margin-top: 0.5rem;
  }
  .dashboard-container .streming-head-right {
    flex-wrap: wrap;
  }
  .dashboard-container .streming-head-right .stream-tab {
    margin-bottom: 0.5rem;
  }
  .dashboard-container .strame-chat-section {
    flex-wrap: wrap;
  }
  .dashboard-container .strame-main {
    padding: 1rem;
    width: 100%;
    min-height: inherit;
    border-radius: 0.75rem 0.75rem 0 0;
  }
  .dashboard-container .chat-main {
    width: 100%;
    border-radius: 0 0 0.75rem 0.75rem;
  }
  .dashboard-container .chat-main .chat-main-input {
    position: inherit;
    top: inherit;
    bottom: inherit;
    left: inherit;
    margin-top: 1rem;
  }
  .product-details-wrap .size-chart-wrp .prefarance-form-list {
    text-align: center;
    margin-bottom: 1rem;
  }
  .product-details-wrap .thumb_img_cont {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 480px) {
  .dashboard-container .prefarance-form-list button.add-links {
    width: 100% !important;
    margin: 0.5rem 0 !important;
  }
  .dashboard-container .team-shop-product-box {
    width: 100%;
    margin-bottom: 1rem;
  }
}
